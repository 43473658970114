import React from 'react';
import PropTypes from 'prop-types';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import CargoItemStatusCell from '../../../../common/cells/CargoItemStatusCell';

const CargoItemDetailBasicInformationResource = ({
  cargoItemId,
  cargoItemCorrelation,
  cargoItemDetail,
  cargoItemItineraryId,
  cargoItemLocationName,
  source,
  destination,
  senderCustomer,
  consigneeCustomer,
}) => (
  <>
    <ResourceProperty label="Código:">{cargoItemId}</ResourceProperty>
    <ResourceProperty label="Correlativo de Encomienda:">
      {cargoItemCorrelation}
    </ResourceProperty>
    <ResourceProperty label="Descripción:">
      {cargoItemDetail.description}
    </ResourceProperty>
    <ResourceProperty label="Cantidad:">
      {cargoItemDetail.quantity}
    </ResourceProperty>
    <ResourceProperty label="Peso:">
      {cargoItemDetail.weight} kg
    </ResourceProperty>
    <ResourceProperty label="Longitud:">
      {cargoItemDetail.length !== null ? cargoItemDetail.length : '-'}
    </ResourceProperty>
    <ResourceProperty label="Alto:">
      {cargoItemDetail.height !== null ? cargoItemDetail.height : '-'}
    </ResourceProperty>
    <ResourceProperty label="Ancho:">
      {cargoItemDetail.width !== null ? cargoItemDetail.width : '-'}
    </ResourceProperty>
    <ResourceProperty label="Origen:">{source}</ResourceProperty>
    <ResourceProperty label="Destino:">{destination}</ResourceProperty>
    {cargoItemItineraryId && (
      <ResourceProperty label="Itinerario:">
        {cargoItemItineraryId}
      </ResourceProperty>
    )}
    {cargoItemLocationName && (
      <ResourceProperty label="Ubicación:">
        {cargoItemLocationName}
      </ResourceProperty>
    )}
    <ResourceProperty label="Estado:">
      <CargoItemStatusCell value={cargoItemDetail.status} />
    </ResourceProperty>
    <h3>Remitente</h3>
    <ResourceProperty label="Nombre:">
      {senderCustomer.fullName}
    </ResourceProperty>
    <ResourceProperty label="Tipo de Documento:">
      {senderCustomer.identificationTypeName}
    </ResourceProperty>
    <ResourceProperty label="Numero de Documento:">
      {senderCustomer.idDocumentNumber}
    </ResourceProperty>
    <ResourceProperty label="Telefono:">
      {senderCustomer.phone !== null ? senderCustomer.phone : '-'}
    </ResourceProperty>
    <h3>Consignado</h3>
    <ResourceProperty label="Nombre:">
      {consigneeCustomer.fullName}
    </ResourceProperty>
    <ResourceProperty label="Tipo de Documento:">
      {consigneeCustomer.identificationTypeName}
    </ResourceProperty>
    <ResourceProperty label="Numero de Documento:">
      {consigneeCustomer.idDocumentNumber}
    </ResourceProperty>
    <ResourceProperty label="Telefono:">
      {consigneeCustomer.phone !== null ? consigneeCustomer.phone : '-'}
    </ResourceProperty>
  </>
);
CargoItemDetailBasicInformationResource.propTypes = {
  cargoItemId: PropTypes.number.isRequired,
  cargoItemCorrelation: PropTypes.string.isRequired,
  cargoItemItineraryId: PropTypes.number,
  cargoItemLocationName: PropTypes.string,
  cargoItemDetail: PropTypes.shape({
    description: PropTypes.string,
    quantity: PropTypes.number.isRequired,
    weight: PropTypes.number,
    length: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number,
    status: PropTypes.string.isRequired,
  }).isRequired,
  source: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
  senderCustomer: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    phone: PropTypes.string,
    identificationTypeName: PropTypes.string.isRequired,
    idDocumentNumber: PropTypes.string.isRequired,
  }).isRequired,
  consigneeCustomer: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    phone: PropTypes.string,
    identificationTypeName: PropTypes.string.isRequired,
    idDocumentNumber: PropTypes.string.isRequired,
  }).isRequired,
};

CargoItemDetailBasicInformationResource.defaultProps = {
  cargoItemItineraryId: 0,
  cargoItemLocationName: '',
};

export default CargoItemDetailBasicInformationResource;
