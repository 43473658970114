import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  getItinerary,
  clearItinerary,
  getItinerarySchedule,
  clearItinerarySchedule,
  getPassengerFlowForItinerary,
  clearPassengerFlowForItinerary,
  getListDriversForItinerary,
  clearDrivers,
} from '../../../../../actions/index';
import Loader from '../../../../common/Loader';
import {
  ITINERARY_PATH,
  ITINERARY_SCHEDULE_PATH,
  OLD_FRONT_CIRCUIT_PATH,
  OLD_FRONT_ROUTE_PATH,
  OLD_FRONT_SEAT_MAP_PATH,
  PRICING_PROFILE_PATH,
} from '../../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../../common/resource//proptypes/CommonPropTypes';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import Alert from '../../../../common/informative/Alert';
import {
  ITINERARY_CREATED_FROM_API_WARNING_MESSAGE,
  ITINERARY_SCHEDULE_WITHOUT_SEGMENT_LIST_MESSAGE,
  ITINERARY_WITHOUT_CABIN_CREW_INFO_MESSAGE,
  ITINERARY_WITHOUT_DRIVER_INFO_MESSAGE,
  ITINERARY_WITHOUT_PASSENGER_FLOW_MESSAGE,
  PENDING_SCHEDULING_WARNING_MESSAGE,
} from '../../../../../config/messages';
import IsActive from '../../../../common/cells/IsActive';
import TimetableTemplate from '../TimetableTemplate';
import Modal from '../../../../common/modal/Modal';
import { Table } from '../../../../common/Table';
import { FULL_DATE_TIME_FORMAT } from '../../../../../config/locale';
import ItineraryToolbar from './ItineraryToolbar';
import {
  ITINERARY_TYPE,
  ITINERARY_TYPE_CONTRACT,
  TRIP_CIRCUIT_STATUS,
} from '../../../../../config/constants';
import NoDataResource from '../../../../common/resource/NoDataResource';
import Content from '../../../../layout/Content';
import LinkToModuleResource from '../../../../common/resource/LinkToModuleResource';
import BadgeList from '../../../../common/BadgeList';
import { tzNormalizeDate } from '../../../../../utils/date';
import Checkbox from '../../../../common/forms/Checkbox';

export class Itinerary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      numberOfUpdates: 0,
      showModal: false,
      modalTitle: '',
      modalBody: null,
    };
  }

  componentDidMount() {
    const {
      dispatchGetItinerary,
      dispatchGetPassengerFlowForItinerary,
      dispatchGetListDriversForItinerary,
      match: {
        params: { id: itineraryId },
      },
    } = this.props;

    dispatchGetItinerary({ itineraryId });
    dispatchGetListDriversForItinerary({ itineraryId });
    dispatchGetPassengerFlowForItinerary({ itineraryId });
  }

  componentDidUpdate() {
    this.onDidUpdate();
  }

  componentWillUnmount() {
    const {
      dispatchClearItinerary,
      dispatchClearItinerarySchedule,
      dispatchClearPassengerFlowForItinerary,
      dispatchClearDrivers,
    } = this.props;

    dispatchClearItinerary();
    dispatchClearItinerarySchedule();
    dispatchClearPassengerFlowForItinerary();
    dispatchClearDrivers();
  }

  onDidUpdate = () => {
    const { itinerary, dispatchGetItinerarySchedule } = this.props;

    const { numberOfUpdates } = this.state;

    if (numberOfUpdates === 0) {
      if (itinerary.get('itineraryScheduleId')) {
        dispatchGetItinerarySchedule({
          itineraryScheduleId: itinerary.get('itineraryScheduleId'),
        });

        this.setState({ numberOfUpdates: 1 });
      }
    }
  };

  renderPassengerFlowModal = (type, passengerFlow) => {
    const title = `${type === 'boarding' ? 'Embarque' : 'Desembarque'}: ${
      passengerFlow.locationName
    } (${passengerFlow[`${type}Passengers`]})`;

    const ticketsData = passengerFlow[`${type}Tickets`];
    const body = (
      <Table
        columns={[
          {
            Header: 'Boleto',
            accessor: 'ticketCode',
            className: 'text-center',
          },
          {
            Header: 'Pasajero',
            accessor: 'customer.fullName',
            className: 'text-center',
          },
          {
            Header: 'Asiento',
            accessor: 'seatReservation.seat.seatNumber',
            className: 'text-center',
          },
        ]}
        data={ticketsData}
        defaultPageSize={ticketsData.length}
        showPagination={false}
      />
    );

    this.setState({ showModal: true, modalTitle: title, modalBody: body });
  };

  renderPassengersFlow = (type, passengerFlow) => {
    let passengers = 0;

    if (passengerFlow[`${type}Passengers`]) {
      passengers = (
        <div
          role="button"
          onClick={() => this.renderPassengerFlowModal(type, passengerFlow)}
          onKeyPress={() => this.renderPassengerFlowModal(type, passengerFlow)}
          tabIndex="0"
          className="clickable"
        >
          <strong>
            <u>{passengerFlow[`${type}Passengers`]}</u>
          </strong>
        </div>
      );
    }
    return passengers;
  };

  render() {
    const {
      breadcrumbs,
      itinerary,
      loading,
      itinerarySchedule,
      loadingItinerarySchedule,
      passengerFlow,
      drivers,
    } = this.props;

    const { showModal, modalTitle, modalBody } = this.state;

    let content;
    let toolbar;

    if (loading || loadingItinerarySchedule || itinerarySchedule.isEmpty()) {
      content = <Loader />;
    } else if (itinerary.isEmpty()) {
      content = <NoDataResource returnPage={ITINERARY_PATH} />;
    } else {
      const {
        createdFromApi,
        itineraryScheduleId,
        followsTemplate,
        isMaciva,
        firstFloorPriceVariation,
        secondFloorPriceVariation,
        type,
        company,
        itineraryCorrelationId,
        route,
        circuit,
        serviceType,
        active,
        seatMap,
        driverCommission,
        cabinCrewCommission,
        departureTime,
        trip,
        id,
      } = itinerary.toJS();

      let createdFromApiMessageAlert = null;

      if (createdFromApi) {
        createdFromApiMessageAlert = (
          <Alert
            type="warning"
            message={ITINERARY_CREATED_FROM_API_WARNING_MESSAGE}
          />
        );
      }

      let templateAlert = null;

      if (itineraryScheduleId) {
        const link = (
          <Link
            to={`${ITINERARY_SCHEDULE_PATH}/${itinerary.get(
              'itineraryScheduleId',
            )}`}
          >
            esta plantilla
          </Link>
        );

        let infoMessageOfFollowsATemplate = (
          <span>
            Este itinerario esta asociado con {link}, pero no la sigue
          </span>
        );

        if (followsTemplate) {
          infoMessageOfFollowsATemplate = (
            <span>Este itinerario sigue {link}</span>
          );
        }

        templateAlert = (
          <Alert type="info" message={infoMessageOfFollowsATemplate} />
        );
      }

      const firstFloorPriceVariationContent =
        firstFloorPriceVariation !== 0 ? (
          <ResourceProperty label="Variación de Precio del Primer Piso:">
            {firstFloorPriceVariation || '-'}
          </ResourceProperty>
        ) : null;

      const secondFloorPriceVariationContent =
        secondFloorPriceVariation !== 0 ? (
          <ResourceProperty label="Variación de Precio del Segundo Piso:">
            {secondFloorPriceVariation || '-'}
          </ResourceProperty>
        ) : null;

      let companyResource = null;

      if (type === ITINERARY_TYPE_CONTRACT) {
        companyResource = (
          <ResourceProperty label="Compañia Contrato:">
            {company ? company.name : '-'}
          </ResourceProperty>
        );
      }

      const basicInformationResource = (
        <Fragment>
          <h3>Información Básica</h3>
          <ResourceProperty label="ID de Correlación Civa:">
            {itineraryCorrelationId || '-'}
          </ResourceProperty>
          <ResourceProperty label="Ruta:">
            <LinkToModuleResource
              text={`${route.id} - ${route.name}`}
              href={`${OLD_FRONT_ROUTE_PATH}/${route.id}`}
              isExternalPath
            />
          </ResourceProperty>
          <ResourceProperty label="Circuito:">
            <LinkToModuleResource
              text={`${circuit.id} - ${circuit.name}`}
              href={`${OLD_FRONT_CIRCUIT_PATH}/${circuit.id}`}
              isExternalPath
            />
          </ResourceProperty>
          <ResourceProperty label="Tipo de Servicio:">
            {serviceType ? serviceType.name : '-'}
          </ResourceProperty>
          <ResourceProperty label="Maciva:">
            <Checkbox checked={isMaciva} />
          </ResourceProperty>
          <ResourceProperty label="Estado:">
            <IsActive value={active} />
          </ResourceProperty>
          <ResourceProperty label="Mapa de Asientos:">
            {seatMap ? (
              <LinkToModuleResource
                text={`${seatMap.id} - ${seatMap.name}`}
                href={`${OLD_FRONT_SEAT_MAP_PATH}/${seatMap.id}`}
                isExternalPath
              />
            ) : (
              '-'
            )}
          </ResourceProperty>
          <ResourceProperty label="Perfil de Precio:">
            <BadgeList
              textArray={itinerary
                .get('itineraryPricingProfiles')
                .map(({ pricingProfile }) => ({
                  text: `${pricingProfile.id} - ${pricingProfile.name}`,
                  href: `${PRICING_PROFILE_PATH}/${pricingProfile.id}`,
                }))}
            />
          </ResourceProperty>
          {firstFloorPriceVariationContent}
          {secondFloorPriceVariationContent}
          <ResourceProperty label="Comisión del Conductor:">
            {driverCommission
              ? `${driverCommission.id} - ${driverCommission.name}`
              : '-'}
          </ResourceProperty>
          <ResourceProperty label="Comisión del Servicio a Bordo:">
            {cabinCrewCommission ? cabinCrewCommission.name : '-'}
          </ResourceProperty>
          <ResourceProperty label="Tipo:">
            {ITINERARY_TYPE[type.toLowerCase()].label}
          </ResourceProperty>
          {companyResource}
        </Fragment>
      );

      let timetableTemplate = (
        <Alert message={ITINERARY_SCHEDULE_WITHOUT_SEGMENT_LIST_MESSAGE} />
      );

      if (itinerarySchedule.get('itineraryScheduleSegmentList').length) {
        timetableTemplate = (
          <TimetableTemplate
            itineraryScheduleSegmentList={itinerarySchedule.get(
              'itineraryScheduleSegmentList',
            )}
            estimatedDepartureTime={departureTime}
          />
        );
      }

      const timetableResource = (
        <Fragment>
          <h3>Horarios</h3>
          <div className="mb-3">{timetableTemplate}</div>
        </Fragment>
      );

      let passengerFlowTable = (
        <Alert
          type="warning"
          message={ITINERARY_WITHOUT_PASSENGER_FLOW_MESSAGE}
        />
      );

      if (!passengerFlow.isEmpty()) {
        const passengerFlowData = passengerFlow.get(
          'passengerFlowForLocationList',
        );

        passengerFlowTable = (
          <div className="mb-3">
            <Table
              columns={[
                {
                  Header: 'Parada Autorizada',
                  accessor: 'locationName',
                  className: 'text-center',
                },
                {
                  Header: 'Embarques',
                  accessor: 'boardingPassengers',
                  className: 'text-center',
                  Cell: (row) =>
                    this.renderPassengersFlow('boarding', row.original),
                },
                {
                  Header: 'Desembarques',
                  accessor: 'leavingPassengers',
                  className: 'text-center',
                  Cell: (row) =>
                    this.renderPassengersFlow('leaving', row.original),
                },
              ]}
              data={passengerFlowData}
              defaultPageSize={passengerFlowData.length}
              showPagination={false}
            />
          </div>
        );
      }

      const passengerFlowResource = (
        <Fragment>
          <h3>Flujo de Pasajeros</h3>
          {passengerFlowTable}
        </Fragment>
      );

      let scheduledItineraryMessage = (
        <div className="mt-3">
          <Alert type="warning" message={PENDING_SCHEDULING_WARNING_MESSAGE} />
        </div>
      );

      let scheduledItineraryResources = null;

      let scheduled = false;

      if (trip) {
        scheduled = true;

        const {
          assignedRegisteredBus,
          tripStatus,
          tripCircuitStatus,
          driverAssignmentList,
          cabinCrewAssignmentList,
          estimatedDepartureTime,
          estimatedArrivalTime,
          actualArrivalTime,
          actualDepartureTime,
          initialOdometerReading,
          finalOdometerReading,
        } = trip;

        const busResource = (
          <Fragment>
            <h3>Bus</h3>
            <ResourceProperty label="Número:">
              {assignedRegisteredBus ? assignedRegisteredBus.companyBusId : '-'}
            </ResourceProperty>
            <ResourceProperty label="Mapa de Asientos:">
              {assignedRegisteredBus ? assignedRegisteredBus.seatMap.name : '-'}
            </ResourceProperty>
            <ResourceProperty label="Matrícula:">
              {assignedRegisteredBus ? assignedRegisteredBus.licensePlate : '-'}
            </ResourceProperty>
            <ResourceProperty label="Estado:">
              {tripStatus.name}
            </ResourceProperty>
          </Fragment>
        );

        const circuitResource = (
          <Fragment>
            <h3>Circuito</h3>
            <ResourceProperty label="Orden dentro del Circuito:">
              {tripCircuitStatus
                ? TRIP_CIRCUIT_STATUS[tripCircuitStatus].label
                : '-'}
            </ResourceProperty>
          </Fragment>
        );

        const driverData = driverAssignmentList;

        let driverResource = (
          <Alert message={ITINERARY_WITHOUT_DRIVER_INFO_MESSAGE} />
        );

        if (driverData.length > 0) {
          driverResource = (
            <div className="mb-3">
              <Table
                columns={[
                  {
                    Header: 'Nombre',
                    accessor: 'driver.customer.fullName',
                    className: 'text-center',
                  },
                  {
                    Header: 'DNI',
                    accessor: 'driver.customer.idDocumentNumber',
                    className: 'text-center',
                  },
                ]}
                data={driverData}
                defaultPageSize={driverData.length}
                showPagination={false}
              />
            </div>
          );
        }

        const cabinCrewData = cabinCrewAssignmentList;

        let cabinCrewResource = (
          <Alert
            type="warning"
            message={ITINERARY_WITHOUT_CABIN_CREW_INFO_MESSAGE}
          />
        );

        if (cabinCrewData.length > 0) {
          cabinCrewResource = (
            <div className="mb-3">
              <Table
                columns={[
                  {
                    Header: 'Nombre',
                    accessor: 'cabinCrew.customer.fullName',
                    className: 'text-center',
                  },
                  {
                    Header: 'DNI',
                    accessor: 'cabinCrew.customer.idDocumentNumber',
                    className: 'text-center',
                  },
                ]}
                data={cabinCrewData}
                defaultPageSize={cabinCrewData.length}
                showPagination={false}
              />
            </div>
          );
        }

        const stadisticResource = (
          <Fragment>
            <h3>Estadísticas</h3>
            <ResourceProperty label="Hora de Salida Estimada:">
              {estimatedDepartureTime
                ? tzNormalizeDate({
                    date: estimatedDepartureTime,
                    format: FULL_DATE_TIME_FORMAT,
                  })
                : '-'}
            </ResourceProperty>
            <ResourceProperty label="Hora de Salida Actual:">
              {actualDepartureTime
                ? tzNormalizeDate({
                    date: actualDepartureTime,
                    format: FULL_DATE_TIME_FORMAT,
                  })
                : '-'}
            </ResourceProperty>
            <ResourceProperty label="Hora de Llegada Estimada:">
              {estimatedArrivalTime
                ? tzNormalizeDate({
                    date: estimatedArrivalTime,
                    format: FULL_DATE_TIME_FORMAT,
                  })
                : '-'}
            </ResourceProperty>
            <ResourceProperty label="Hora de Llegada Actual:">
              {actualArrivalTime
                ? tzNormalizeDate({
                    date: actualArrivalTime,
                    format: FULL_DATE_TIME_FORMAT,
                  })
                : '-'}
            </ResourceProperty>
            <ResourceProperty label="Lectura de Odómetro Inicial:">
              {initialOdometerReading || '-'}
            </ResourceProperty>
            <ResourceProperty label="Lectura de Odómetro Final:">
              {finalOdometerReading || '-'}
            </ResourceProperty>
          </Fragment>
        );

        scheduledItineraryMessage = null;

        scheduledItineraryResources = (
          <Fragment>
            {busResource}
            {circuitResource}
            <h3>Conductores</h3>
            {driverResource}
            <h3>Servicio a Bordo</h3>
            {cabinCrewResource}
            {stadisticResource}
          </Fragment>
        );
      }

      content = (
        <Fragment>
          {createdFromApiMessageAlert}
          {templateAlert}
          {scheduledItineraryMessage}
          {basicInformationResource}
          {timetableResource}
          {passengerFlowResource}
          {scheduledItineraryResources}
          <Modal
            show={showModal}
            title={modalTitle}
            body={modalBody}
            onClickClose={() => this.setState({ showModal: false })}
          />
        </Fragment>
      );

      toolbar = (
        <ItineraryToolbar
          itineraryId={id}
          drivers={drivers}
          scheduled={scheduled}
          type={type}
          itinerary={itinerary}
          documentUrls={this.state.grtPDFUrls}
        />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        toolbar={toolbar}
        title="Itinerario"
        content={content}
      />
    );
  }
}

const mapStateToProps = ({ ItineraryUnit }) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    { text: 'Itinerarios', href: ITINERARY_PATH },
    { text: 'Ver', href: '' },
  ],
  itinerary: ItineraryUnit.Itinerary.getIn(['current', 'content']),
  loading: !ItineraryUnit.Itinerary.getIn(['current', 'activity']).isEmpty(),
  itinerarySchedule: ItineraryUnit.ItinerarySchedule.getIn([
    'current',
    'content',
  ]),
  loadingItinerarySchedule: !ItineraryUnit.ItinerarySchedule.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  passengerFlow: ItineraryUnit.Itinerary.getIn(['current', 'passengerFlow']),
  drivers: ItineraryUnit.Itinerary.getIn(['current', 'drivers']),
});

const mapDispatchToProps = {
  dispatchGetItinerary: getItinerary,
  dispatchClearItinerary: clearItinerary,
  dispatchGetItinerarySchedule: getItinerarySchedule,
  dispatchClearItinerarySchedule: clearItinerarySchedule,
  dispatchGetPassengerFlowForItinerary: getPassengerFlowForItinerary,
  dispatchClearPassengerFlowForItinerary: clearPassengerFlowForItinerary,
  dispatchGetListDriversForItinerary: getListDriversForItinerary,
  dispatchClearDrivers: clearDrivers,
};

Itinerary.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  match: matchPropTypes.isRequired,
  itinerary: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchGetItinerary: PropTypes.func.isRequired,
  dispatchClearItinerary: PropTypes.func.isRequired,
  itinerarySchedule: PropTypes.instanceOf(Immutable.Map).isRequired,
  loadingItinerarySchedule: PropTypes.bool.isRequired,
  dispatchGetItinerarySchedule: PropTypes.func.isRequired,
  dispatchClearItinerarySchedule: PropTypes.func.isRequired,
  passengerFlow: PropTypes.instanceOf(Immutable.Map).isRequired,
  dispatchGetPassengerFlowForItinerary: PropTypes.func.isRequired,
  dispatchClearPassengerFlowForItinerary: PropTypes.func.isRequired,
  dispatchGetListDriversForItinerary: PropTypes.func.isRequired,
  dispatchClearDrivers: PropTypes.func.isRequired,
  drivers: PropTypes.instanceOf(Immutable.Set).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Itinerary);
