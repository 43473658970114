import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  clearLiveItinerarySalesControl,
  getLiveItinerarySalesControl,
} from '../../../../../actions';
import { INT_DATE_FORMAT } from '../../../../../config/locale';
import { DEFAULT_QUERY } from '../../../../../config/queries';
import { isLocalEnvironment } from '../../../../../config/environments';
import { padStart } from '../../../../../utils/string';
import { tzNormalizeDate } from '../../../../../utils/date';
import LiveItinerarySalesControlSearchForm from './LiveItinerarySalesControlSearchForm';
import LiveItinerarySalesControlSearchResult from './LiveItinerarySalesControlSearchResult';

const LiveItinerarySalesControlSearch = ({
  dispatchClearLiveItinerarySalesControl,
  dispatchGetLiveItinerarySalesControl,
}) => {
  const timeoutRef = useRef(null);

  const [searchFormValues, setSearchFormValues] = useState({
    sourceLocationId: 1,
    destinationLocationId: 1,
    toDayString: tzNormalizeDate({ format: INT_DATE_FORMAT }),
    fromDayString: tzNormalizeDate({ format: INT_DATE_FORMAT }),
    ...DEFAULT_QUERY,
    size: 100,
  });

  let liveItinerarySalesParams = localStorage.getItem(
    'LiveItinerarySalesParams',
  );

  const fetchData = (query) => {
    dispatchGetLiveItinerarySalesControl(query);
  };

  const refreshData = () => {
    liveItinerarySalesParams = localStorage.getItem('LiveItinerarySalesParams');
    if (liveItinerarySalesParams) {
      const params = JSON.parse(liveItinerarySalesParams);
      dispatchGetLiveItinerarySalesControl(params);
    }
  };

  useEffect(() => {
    localStorage.removeItem('LiveItinerarySalesParams');

    return () => {
      if (timeoutRef.current) {
        clearInterval(timeoutRef.current);
      }
    };
  }, []);

  const onSubmit = (formValues) => {
    const newFormValues = {
      toDayString: tzNormalizeDate({
        date: formValues.toDateString,
        format: INT_DATE_FORMAT,
      }),
      fromDayString: tzNormalizeDate({
        date: formValues.fromDateString,
        format: INT_DATE_FORMAT,
      }),
      ...DEFAULT_QUERY,
      size: 100,
    };

    if (formValues.sourceLocationId)
      newFormValues.sourceLocationId = formValues.sourceLocationId.value;

    if (formValues.destinationLocationId)
      newFormValues.destinationLocationId =
        formValues.destinationLocationId.value;

    if (formValues.geographicZoneId)
      newFormValues.geographicZoneId = formValues.geographicZoneId.value;

    if (formValues.departureTimeString) {
      let utcTime = formValues.departureTimeString;

      if (!isLocalEnvironment()) {
        // Convert local time to utc time adding 5 hours
        const [localHour, minute] = utcTime.split(':');
        let utcHour = +localHour + 5;
        if (+localHour > 18) utcHour -= 24;
        utcHour = padStart(utcHour, 2);
        utcTime = `${utcHour}:${minute}`;
      }

      newFormValues.departureTimeHourMinuteStringUtc = utcTime;
    }

    setSearchFormValues(newFormValues);

    dispatchClearLiveItinerarySalesControl();

    if (timeoutRef.current) {
      clearInterval(timeoutRef.current);
    }

    localStorage.setItem(
      'LiveItinerarySalesParams',
      JSON.stringify(newFormValues),
    );

    fetchData(newFormValues);

    timeoutRef.current = setInterval(() => {
      refreshData();
    }, 300000);
  };

  const dateNow = tzNormalizeDate();

  return (
    <div>
      <LiveItinerarySalesControlSearchForm
        onSubmit={onSubmit}
        initialValues={{
          departureDayString: dateNow,
          fromDateString: tzNormalizeDate(),
          toDateString: tzNormalizeDate(),
        }}
        sourceLocationIsRequired={false}
        destinationLocationIsRequired={false}
        geographicZoneIsRequired={false}
      />
      <LiveItinerarySalesControlSearchResult
        searchFormValues={searchFormValues}
        fetchData={fetchData}
      />
    </div>
  );
};

LiveItinerarySalesControlSearch.propTypes = {
  dispatchGetLiveItinerarySalesControl: PropTypes.func.isRequired,
  dispatchClearLiveItinerarySalesControl: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchGetLiveItinerarySalesControl: getLiveItinerarySalesControl,
  dispatchClearLiveItinerarySalesControl: clearLiveItinerarySalesControl,
};

export default connect(
  null,
  mapDispatchToProps,
)(LiveItinerarySalesControlSearch);
