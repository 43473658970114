import React from 'react';
import { connect } from 'react-redux';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import UnloadingCargoForm from './UnloadingCargoForm';
import Content from '../../../layout/Content';

const UnloadingCargo = ({ breadcrumbs }) => (
  <Content
    breadcrumbs={breadcrumbs}
    title="Descarga de encomiendas"
    subtitle="Descarga de encomiendas de un itinerario"
    content={<UnloadingCargoForm />}
  />
);

UnloadingCargo.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Descarga de encomiendas',
      href: '',
    },
  ],
});

export default connect(mapStateToProps)(UnloadingCargo);
