import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_ITINERARIES,
  GET_ITINERARIES,
  CLEAR_ITINERARIES,
  FLAG_ITINERARY_ACTIVITY,
  GET_ITINERARY,
  CLEAR_ITINERARY,
  GET_LIST_DRIVERS,
  CLEAR_LIST_DRIVERS,
  GET_PASSENGER_FLOW,
  CLEAR_PASSENGER_FLOW,
  GET_CURRENT_USER_SPOT_IN_ITINERARY,
  CLEAR_CURRENT_USER_SPOT_IN_ITINERARY,
  GET_ITINERARY_PROMISES,
  CLEAR_ITINERARY_PROMISES,
  FLAG_GETTING_ITINERARY_PROMISES,
} from '../types';
import { DEFAULT_QUERY, DEFAULT_QUERY_GET_ALL } from '../../config/queries';
import {
  ITINERARY_LIST_ENDPOINT,
  ITINERARY_FOR_COUNTER_ENDPOINT,
  generateItineraryTripStatusEndpoint,
  SEATING_CHART_ENDPOINT,
  generateListDriversForItineraryEndpoint,
  generatePassengerFlowForItineraryEndpoint,
  generateCurrentUserSpotInItineraryEndpoint,
  BAGGAGE_MANIFEST_ENDPOINT,
  ITINERARY_ENDPOINT,
  generateNewTripEndpoint,
  ITINERARIES_BY_TEMPLATE_ENDPOINT,
  UPDATE_ACTIVE_STATUS_OF_ITINERARIES_ENDPOINT,
  generateGetItineraryForCounterEndpoint,
  generateGetManifestEndpoint,
  ITINERARY_BETWEEN_DATES_ENDPOINT,
  ASSIGN_BUSSES_TO_ITINERARY_ENDPOINT,
  generateNotFuelReportedItineraries,
  generateGrtBillingByItineraryEndpoint,
  generateGrrBillingByItineraryEndpoint,
  generateGrtBillingByItineraryPdfEndpointV1,
  generateGrtBillingByItineraryPdfEndpointV2,
} from '../../config/endpoints';
import {
  DEFAULT_DELETE_CONFIG,
  DEFAULT_GET_CONFIG,
  DEFAULT_PATCH_CONFIG,
  DEFAULT_POST_CONFIG,
  DEFAULT_PUT_CONFIG,
} from '../../config/rest';
import {
  handleResponseError,
  isErrorResponse,
} from '../../utils/error-handlers';
import {
  ITINERARY_FOR_COUNTER_PATH,
  ITINERARY_PATH,
  ITINERARY_SCHEDULE_PATH,
} from '../../config/paths';

const flagGettingItineraries = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_ITINERARIES,
    payload: flag,
  });

const getItineraries = async (tableFilters) => async (dispatch) => {
  dispatch(flagGettingItineraries(true));
  try {
    const parameters = {
      ...DEFAULT_QUERY,
      ...tableFilters,
    };

    const url = `${ITINERARY_LIST_ENDPOINT}?${QueryString.stringify(
      parameters,
    )}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    const serverError = await handleResponseError(response);
    if (serverError) {
      toastr.error('Error', serverError);
      return dispatch(flagGettingItineraries(false));
    }
    const itineries = await response.json();
    dispatch({
      type: GET_ITINERARIES,
      payload: itineries,
    });
    return dispatch(flagGettingItineraries(false));
  } catch ({ message }) {
    toastr.error('Error', 'obteniendo itinerarios');
    return dispatch(flagGettingItineraries(false));
  }
};

const clearItineraries = () => (dispatch) =>
  dispatch({
    type: CLEAR_ITINERARIES,
  });

const flagItineraryActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_ITINERARY_ACTIVITY,
    payload: flag,
  });

const getItineraryForCounter = async (itineraryId) => async (dispatch) => {
  try {
    dispatch(flagItineraryActivity(true));
    const url = `${ITINERARY_FOR_COUNTER_ENDPOINT}/${itineraryId}`;
    const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
    await isErrorResponse(response);
    const itinerary = await response.json();
    dispatch({ type: GET_ITINERARY, payload: itinerary });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagItineraryActivity(false));
  }
};

const clearItinerary = () => (dispatch) =>
  dispatch({
    type: CLEAR_ITINERARY,
  });

const searchItinerary = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingItineraries(true));

    const query = QueryString.stringify(tableFilters);
    const url = `${ITINERARY_FOR_COUNTER_ENDPOINT}?${query}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response);
    const itineraries = await response.json();
    dispatch({
      type: GET_ITINERARIES,
      payload: itineraries,
    });
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagGettingItineraries(false));
  }
};

const searchItineraryBetweenDates =
  async (tableFilters) => async (dispatch) => {
    try {
      dispatch(flagGettingItineraries(true));

      const query = QueryString.stringify(tableFilters);

      const url = `${ITINERARY_BETWEEN_DATES_ENDPOINT}?${query}`;

      const response = await fetch(url, DEFAULT_GET_CONFIG);

      await isErrorResponse(response);

      const itineraries = await response.json();

      dispatch({
        type: GET_ITINERARIES,
        payload: itineraries,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingItineraries(false));
    }
  };

const patchItinerary =
  async (
    itineraryId,
    {
      companyBusId,
      driverIdList,
      cabinCrewIdList,
      actualDepartureTime,
      actualArrivalTime,
      comment,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagItineraryActivity(true));
      const payload = {
        id: itineraryId,
        companyBusId,
        driverIdList,
        cabinCrewIdList,
        actualDepartureTime,
        actualArrivalTime,
        comment,
      };

      const url = generateGetItineraryForCounterEndpoint(itineraryId);

      const response = await fetch(url, {
        ...DEFAULT_PATCH_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response);
      const itinerary = await response.json();
      dispatch({
        type: GET_ITINERARY,
        payload: itinerary,
      });
      dispatch(push(`${ITINERARY_FOR_COUNTER_PATH}/${itinerary.id}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagItineraryActivity(false));
    }
  };

const patchTripStatus =
  async (itineraryId, tripStatusId) => async (dispatch) => {
    try {
      dispatch(flagItineraryActivity(true));
      const payload = tripStatusId;
      const url = generateItineraryTripStatusEndpoint(itineraryId);
      const response = await fetch(url, {
        ...DEFAULT_PATCH_CONFIG,
        body: payload,
      });
      await isErrorResponse(response);
      const itinerary = await response.json();
      dispatch({
        type: GET_ITINERARY,
        payload: itinerary,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagItineraryActivity(false));
    }
  };

const getSeatingChart =
  async ({ itineraryId }) =>
  async (dispatch) => {
    try {
      dispatch(flagItineraryActivity(true));
      const url = `${SEATING_CHART_ENDPOINT}/${itineraryId}`;
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(response);
      const seatingChart = await response.json();
      return seatingChart;
    } catch ({ message }) {
      toastr.error('Error', message);
      return null;
    } finally {
      dispatch(flagItineraryActivity(false));
    }
  };

const getManifest =
  async ({ itineraryId }) =>
  async (dispatch) => {
    try {
      dispatch(flagItineraryActivity(true));
      const url = generateGetManifestEndpoint(itineraryId);
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(response);
      const manifest = await response.json();
      return manifest;
    } catch ({ message }) {
      toastr.error('Error', message);
      return null;
    } finally {
      dispatch(flagItineraryActivity(false));
    }
  };

const getListDriversForItinerary =
  async ({ itineraryId }) =>
  async (dispatch) => {
    try {
      dispatch(flagItineraryActivity(true));
      const url = generateListDriversForItineraryEndpoint(itineraryId);
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response);
      const drivers = await response.json();
      dispatch({
        type: GET_LIST_DRIVERS,
        payload: drivers,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagItineraryActivity(false));
    }
  };

const clearListDriversForItinerary = () => (dispatch) =>
  dispatch({
    type: CLEAR_LIST_DRIVERS,
  });

const getPassengerFlowForItinerary =
  async ({ itineraryId }) =>
  async (dispatch) => {
    try {
      dispatch(flagItineraryActivity(true));
      const url = generatePassengerFlowForItineraryEndpoint(itineraryId);
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response);
      const flow = await response.json();
      dispatch({
        type: GET_PASSENGER_FLOW,
        payload: flow,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagItineraryActivity(false));
    }
  };

const clearPassengerFlowForItinerary = () => (dispatch) =>
  dispatch({
    type: CLEAR_PASSENGER_FLOW,
  });

const getCurrentUserSpotInItinerary =
  async ({ itineraryId }) =>
  async (dispatch) => {
    try {
      dispatch(flagItineraryActivity(true));
      const url = generateCurrentUserSpotInItineraryEndpoint(itineraryId);
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response);
      const spot = await response.json();
      dispatch({
        type: GET_CURRENT_USER_SPOT_IN_ITINERARY,
        payload: spot,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagItineraryActivity(false));
    }
  };

const clearCurrentUserSpotInItinerary = () => (dispatch) =>
  dispatch({
    type: CLEAR_CURRENT_USER_SPOT_IN_ITINERARY,
  });

const getBaggageManifest =
  async ({ itineraryId }) =>
  async (dispatch) => {
    try {
      dispatch(flagItineraryActivity(true));
      const url = `${BAGGAGE_MANIFEST_ENDPOINT}?itineraryId=${itineraryId}`;
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(response);
      const manifest = await response.json();
      return manifest;
    } catch ({ message }) {
      toastr.error('Error', message);
      return null;
    } finally {
      dispatch(flagItineraryActivity(false));
    }
  };

const getItinerary =
  async ({ itineraryId }) =>
  async (dispatch) => {
    try {
      dispatch(flagItineraryActivity(true));
      const url = `${ITINERARY_ENDPOINT}/${itineraryId}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response);
      const itinerary = await response.json();
      dispatch({ type: GET_ITINERARY, payload: itinerary });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagItineraryActivity(false));
    }
  };

const getItineraryJson =
  async ({ itineraryId }) =>
  async (dispatch) => {
    try {
      dispatch(flagItineraryActivity(true));
      const url = `${ITINERARY_ENDPOINT}/${itineraryId}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response);
      const itinerary = await response.json();
      return itinerary;
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagItineraryActivity(false));
    }
    return null;
  };

const postNewTrip =
  async ({
    itineraryId,
    assignedRegisteredBusId,
    tripStatusId,
    initialOdometerReading,
    finalOdometerReading,
    tripCircuitStatus,
    estimatedDepartureTime,
    actualDepartureTime,
    estimatedArrivalTime,
    actualArrivalTime,
    driverAssignmentList,
    cabinCrewAssignmentList,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagItineraryActivity(true));

      const payload = {
        itineraryId,
        assignedRegisteredBusId,
        tripStatusId,
        initialOdometerReading,
        finalOdometerReading,
        tripCircuitStatus,
        estimatedDepartureTime,
        actualDepartureTime,
        estimatedArrivalTime,
        actualArrivalTime,
        driverAssignmentList,
        cabinCrewAssignmentList,
      };

      const url = generateNewTripEndpoint(itineraryId);

      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });

      await isErrorResponse(response);

      await response.json();

      dispatch(push(`${ITINERARY_PATH}/${itineraryId}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagItineraryActivity(false));
    }
  };

const putItinerary =
  async (
    itineraryId,
    {
      routeId,
      seatMapId,
      departureTime,
      departureDate,
      active,
      type,
      itineraryScheduleId,
      followsTemplate,
      itineraryPricingProfiles,
      tripId,
      isInternal,
      isMaciva,
      driverCommissionId,
      itineraryCode,
      circuitId,
      fuelProfileId,
      cabinCrewCommissionId,
      serviceTypeId,
      itineraryCorrelationId,
      createdFromApi,
      archived,
      itineraryMigrationGroupId,
      foodGroupId,
      seatMapAssigments,
      firstFloorPriceVariation,
      secondFloorPriceVariation,
      version,
      extraordinary,
      comment,
      totalIncome,
      companyId,
      trip,
    },
  ) =>
  async (dispatch) => {
    try {
      dispatch(flagItineraryActivity(true));
      const payload = {
        id: itineraryId,
        routeId,
        seatMapId,
        departureTime,
        departureDate,
        active,
        type,
        itineraryScheduleId,
        followsTemplate,
        itineraryPricingProfiles,
        tripId,
        isInternal,
        isMaciva,
        driverCommissionId,
        itineraryCode,
        circuitId,
        fuelProfileId,
        cabinCrewCommissionId,
        serviceTypeId,
        itineraryCorrelationId,
        createdFromApi,
        archived,
        itineraryMigrationGroupId,
        foodGroupId,
        seatMapAssigments,
        firstFloorPriceVariation,
        secondFloorPriceVariation,
        version,
        extraordinary,
        comment,
        totalIncome,
        companyId,
        trip,
      };
      const url = `${ITINERARY_ENDPOINT}/${itineraryId}`;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response);
      const itinerary = await response.json();
      dispatch(push(`${ITINERARY_PATH}/${itinerary.id}`));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagItineraryActivity(false));
    }
  };

const deleteItinerary =
  async ({ itineraryId }) =>
  async (dispatch) => {
    try {
      dispatch(flagItineraryActivity(true));
      const url = `${ITINERARY_ENDPOINT}/${itineraryId}`;
      const response = await fetch(url, {
        ...DEFAULT_DELETE_CONFIG,
      });
      await isErrorResponse(response);
      await response.json();
      dispatch(push(ITINERARY_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagItineraryActivity(false));
    }
  };

const getItinerariesByTemplate =
  async ({ itineraryScheduleId }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingItineraries(true));
      const parameters = { ...DEFAULT_QUERY_GET_ALL };
      const url = `${ITINERARIES_BY_TEMPLATE_ENDPOINT}/${itineraryScheduleId}?${QueryString.stringify(
        parameters,
      )}`;
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(response);
      const itineraries = await response.json();
      dispatch({
        type: GET_ITINERARIES,
        payload: itineraries,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingItineraries(false));
    }
  };

const putUpdateActiveStatusOfItineraries =
  async ({ itineraryList }) =>
  async (dispatch) => {
    try {
      dispatch(flagItineraryActivity(true));
      const url = UPDATE_ACTIVE_STATUS_OF_ITINERARIES_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_PUT_CONFIG,
        body: JSON.stringify(itineraryList),
      });
      await isErrorResponse(response);
      await response.json();
      dispatch(push(ITINERARY_SCHEDULE_PATH));
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagItineraryActivity(false));
    }
  };

const putAssignBusses = async (values) => async (dispatch) => {
  try {
    dispatch(flagGettingItineraries(true));
    const response = await fetch(ASSIGN_BUSSES_TO_ITINERARY_ENDPOINT, {
      ...DEFAULT_PUT_CONFIG,
      body: JSON.stringify(values),
    });
    isErrorResponse(response);
    const responseList = await response.json();
    responseList.reverse().forEach((element) => {
      if (!element.status) {
        toastr.warning('Advertencia', element.message, {
          timeOut: 0,
          showCloseButton: true,
          progressBar: true,
          removeOnHover: false,
        });
      }
    });
    return responseList;
  } catch ({ message }) {
    toastr.error('Error', message);
    return null;
  } finally {
    dispatch(flagGettingItineraries(false));
  }
};

const getItinerariesNotAddedToItineraryGroup =
  async ({ registeredBusId }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingItineraries(true));
      const url = `${generateNotFuelReportedItineraries(registeredBusId)}`;
      const response = await fetch(url, DEFAULT_GET_CONFIG);
      await isErrorResponse(response);
      const itineraries = await response.json();

      dispatch({
        type: GET_ITINERARIES,
        payload: itineraries,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingItineraries(false));
    }
  };

const flagGettingItineraryPromises = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_ITINERARY_PROMISES,
    payload: flag,
  });

const getItinerariesByItineraryIdListAsync = async ({ itineraryIdList }) => {
  const promises = itineraryIdList.map(({ itineraryId }) =>
    fetch(`${ITINERARY_ENDPOINT}/${itineraryId}`, {
      ...DEFAULT_GET_CONFIG,
    }).then((response) => response.json()),
  );

  const itineraries = await Promise.allSettled(promises);

  return itineraries;
};

const getItinerariesByItineraryIdList =
  async ({ itineraryIdList }) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingItineraryPromises(true));

      const itineraries = await getItinerariesByItineraryIdListAsync({
        itineraryIdList,
      });

      dispatch({
        type: GET_ITINERARY_PROMISES,
        payload: itineraries,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingItineraryPromises(false));
    }
  };

const clearItineraryPromises = () => (dispatch) =>
  dispatch({
    type: CLEAR_ITINERARY_PROMISES,
  });

const postBillingGrtByItinerary = async (itineraryId) => async (dispatch) => {
  try {
    dispatch(flagItineraryActivity(true));

    const url = generateGrtBillingByItineraryEndpoint(itineraryId);

    const promise = await fetch(url, {
      ...DEFAULT_POST_CONFIG,
    });
    await isErrorResponse(promise);
    await promise.json();

    toastr.success('Exito', 'Proceso terminado correctamente');
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagItineraryActivity(false));
  }
};

const postBillingGrrByItinerary = async (itineraryId) => async (dispatch) => {
  try {
    dispatch(flagItineraryActivity(true));

    const url = generateGrrBillingByItineraryEndpoint(itineraryId);

    const promise = await fetch(url, {
      ...DEFAULT_POST_CONFIG,
    });
    await isErrorResponse(promise);
    await promise.json();

    toastr.success('Exito', 'Proceso terminado correctamente');
  } catch ({ message }) {
    toastr.error('Error', message);
  } finally {
    dispatch(flagItineraryActivity(false));
  }
};

const getCarrierRemissionGuidePDFByItineraryV1 =
  async (itineraryId) => async (dispatch) => {
    try {
      dispatch(flagItineraryActivity(true));
      const url = generateGrtBillingByItineraryPdfEndpointV1(itineraryId);

      const response = await fetch(url, {
        ...DEFAULT_GET_CONFIG,
      });
      await isErrorResponse(response);
      const URLs = await response.json();
      return URLs;
    } catch ({ message }) {
      toastr.error('Error', message);
      return null;
    } finally {
      dispatch(flagItineraryActivity(false));
    }
  };

const getCarrierRemissionGuidePDFByItineraryV2 =
  async (itineraryId) => async (dispatch) => {
    try {
      dispatch(flagItineraryActivity(true));
      const url = generateGrtBillingByItineraryPdfEndpointV2(itineraryId);

      const response = await fetch(url, {
        ...DEFAULT_GET_CONFIG,
      });
      await isErrorResponse(response);
      const URLs = await response.json();
      return URLs;
    } catch ({ message }) {
      toastr.error('Error', message);
      return null;
    } finally {
      dispatch(flagItineraryActivity(false));
    }
  };
export {
  getItineraries,
  clearItineraries,
  searchItinerary,
  getItineraryForCounter,
  clearItinerary,
  patchItinerary,
  patchTripStatus,
  flagItineraryActivity,
  getSeatingChart,
  getManifest,
  getListDriversForItinerary,
  clearListDriversForItinerary,
  getPassengerFlowForItinerary,
  clearPassengerFlowForItinerary,
  getCurrentUserSpotInItinerary,
  clearCurrentUserSpotInItinerary,
  getBaggageManifest,
  getItinerary,
  postNewTrip,
  putItinerary,
  deleteItinerary,
  getItinerariesByTemplate,
  putUpdateActiveStatusOfItineraries,
  searchItineraryBetweenDates,
  putAssignBusses,
  getItinerariesNotAddedToItineraryGroup,
  getItinerariesByItineraryIdList,
  clearItineraryPromises,
  getItinerariesByItineraryIdListAsync,
  postBillingGrtByItinerary,
  postBillingGrrByItinerary,
  getCarrierRemissionGuidePDFByItineraryV1,
  getCarrierRemissionGuidePDFByItineraryV2,
  getItineraryJson,
};
