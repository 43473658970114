import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import ModuleList from '../../../layout/ModuleList';
import { CARGO_PATH, NEW_CARGO_PATH } from '../../../../config/paths';
import { getParcels, clearParcels } from '../../../../actions/cargo/Cargo';
import { PARCELS_COLUMNS } from '../../../../config/columns';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';

export class Parcels extends Component {
  constructor(props) {
    super(props);
    this.columns = PARCELS_COLUMNS;
  }

  componentWillUnmount() {
    this.props.dispatchClearParcels();
  }

  render() {
    const { parcels, loading, breadcrumbs, dispatchGetParcels } = this.props;
    const data = parcels.get('content') || [];
    const pages = parcels.get('totalPages') || null;
    const defaultPageSize = parcels.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <ModuleList
        title="Carga"
        buttonPath={NEW_CARGO_PATH}
        breadcrumbs={breadcrumbs}
        tableStructure={{
          columns: this.columns,
          data,
          pages,
          defaultPageSize,
          filterable: true,
          fetchData: dispatchGetParcels,
          loading,
          modelPath: CARGO_PATH,
          navigateToModelOnRowClick: true,
        }}
      />
    );
  }
}

const mapDispatchToProps = {
  dispatchGetParcels: getParcels,
  dispatchClearParcels: clearParcels,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Carga',
      href: CARGO_PATH,
    },
  ],
  parcels: CargoUnit.Cargo.getIn(['all', 'content']),
  loading: CargoUnit.Cargo.getIn(['all', 'loading']),
});

Parcels.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  parcels: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  dispatchGetParcels: PropTypes.func.isRequired,
  dispatchClearParcels: PropTypes.func.isRequired,
};

Parcels.defaultProps = {
  parcels: null,
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Parcels);
