import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAgencies, clearAgencies } from '../../../../actions/index';
import Select from './Select';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { optionsPropTypes } from './SelectPropTypes';

const AgencySelect = ({
  dispatchGetAgencies,
  dispatchClearAgencies,
  agencies,
  isLoading,
  isMulti,
  input,
  meta,
  isDisabled,
}) => {
  useLayoutEffect(() => () => dispatchClearAgencies(), []);

  const handleInputChange = (inputValue) =>
    inputValue.trim().length &&
    inputValue.trim().length >= 4 &&
    dispatchGetAgencies({ query: inputValue });

  const agencyFilterOption = (options) => options;

  return (
    <Select
      options={agencies}
      isLoading={isLoading}
      isMulti={isMulti}
      input={input}
      meta={meta}
      isDisabled={isDisabled}
      onInputChange={handleInputChange}
      filterOption={agencyFilterOption}
      placeholder="Ingrese ubicación"
    />
  );
};

AgencySelect.propTypes = {
  dispatchGetAgencies: PropTypes.func.isRequired,
  dispatchClearAgencies: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  input: inputPropTypes,
  agencies: optionsPropTypes,
  meta: metaPropTypes,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

AgencySelect.defaultProps = {
  isLoading: false,
  isDisabled: false,
  input: inputDefaultProps,
  meta: metaDefaultProps,
  isMulti: false,
  agencies: [],
};

const mapStateToProps = ({ HumanResourcesUnit }) => ({
  agencies: HumanResourcesUnit.Agency.getIn(['all', 'content', 'content']).map(
    (agency) => ({ ...agency, value: agency.id, label: agency.name }),
  ),
  loading: HumanResourcesUnit.Agency.getIn(['all', 'loading']),
});

const mapDispatchToprops = {
  dispatchGetAgencies: getAgencies,
  dispatchClearAgencies: clearAgencies,
};

export default connect(mapStateToProps, mapDispatchToprops)(AgencySelect);
