import React from 'react';
import PropTypes from 'prop-types';
import Badge from '../Badge';

const ParcelPaymentStatusCell = ({ value }) => {
  switch (value) {
    case 'PAID':
      return <Badge color="success" text="PAGADO" />;
    default:
      return <Badge color="warning" text="ESPERANDO PAGO" />;
  }
};

ParcelPaymentStatusCell.propTypes = {
  value: PropTypes.string,
};

ParcelPaymentStatusCell.defaultProps = {
  value: '',
};

export default ParcelPaymentStatusCell;
