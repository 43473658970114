import React from 'react';
import PropTypes from 'prop-types';
import './SeatMapElement.css';
import { SEAT_STATUS } from '../../../config/constants';

const SeatMapElement = ({
  isSeat,
  size = 2,
  elementIcon,
  seatNumber,
  free,
  seatStatus,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onKeyPress,
  onFocus,
  onBlur,
  id,
  selected,
  className,
  disabled,
}) => {
  let classes = `${className} fa-stack fa-lg seat-map-cell-icon`;

  if (selected) {
    classes += ' selectedSeat';
  }

  if (seatStatus === SEAT_STATUS.OCCUPIED) {
    classes += ' taken';
  }

  if (seatStatus === SEAT_STATUS.IN_PROGRESS) {
    classes += ' inProgress';
  }

  if (seatStatus === SEAT_STATUS.CALL_CENTER_RESERVATION) {
    classes += ' callCenterReservation';
  }

  if (disabled) {
    classes += ' disabled';
  }

  return (
    <div
      id={id}
      className={classes}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onKeyPress={onKeyPress}
      onFocus={onFocus}
      onBlur={onBlur}
      role="button"
      tabIndex={isSeat && free ? 0 : null}
    >
      {isSeat ? <i className="fa fa-stack-1x seat-number">{seatNumber}</i> : ''}
      <i className={`fa fa-${size}x ${elementIcon}`} />
    </div>
  );
};

SeatMapElement.propTypes = {
  id: PropTypes.string.isRequired,
  seatNumber: PropTypes.number,
  seatStatus: PropTypes.string.isRequired,
  size: PropTypes.number,
  elementIcon: PropTypes.string.isRequired,
  free: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  isSeat: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

SeatMapElement.defaultProps = {
  size: 2,
  seatNumber: null,
  disabled: false,
};

export default SeatMapElement;
