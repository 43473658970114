import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, FormGroup, InputGroupText, Label, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import FormItem from '../../../common/forms/FormItem';
import CustomerInputGroup from '../../../common/forms/CustomerInputGroup';
import {
  isRequired,
  validateEndDate,
  validateIntegerNumber,
  validateNumber,
} from '../../../../utils/validators';
import BusinessInputGroup from '../../../common/forms/BusinessInputGroup';
import Loader from '../../../common/Loader';
import TextInput from '../../../common/forms/input/TextInput';
import VoucherTypeSelect from '../../../common/forms/select/VoucherTypeSelect';
import DatePicker from '../../../common/forms/input/DatePicker';
import {
  MANUAL_VOUCHER_SERVICE_TYPE,
  MANUAL_VOUCHER_PAYMENT_TYPE,
  CURRENCY_CODE,
  CONTRACT_DOCUMENT_TYPE,
  SUNAT_CREDIT_NOTE_CODE,
  SUNAT_DEBIT_NOTE_CODE,
  SUNAT_TICKET_CODE,
  SUNAT_INVOICE_CODE,
} from '../../../../config/constants';
import { enumToSelectOptions } from '../../../../utils/enum';
import Select from '../../../common/forms/select/Select';
import DynamicForm from '../../../common/forms/DynamicForm';
import { generateManualVoucherFormColumns } from '../../../../config/dynamicFormFields';
import { currencyToNumber, numberFormatter } from '../../../../utils/number';
import {
  optionPropTypes,
  optionsPropTypes,
} from '../../../common/forms/select/SelectPropTypes';
import PrivateServiceSearchButton from '../../../common/button/PrivateServiceSearchButton';
import ManualVoucherSearchInput from '../../../common/forms/input/ManualVoucherSearchInput';
import FormFooter from '../../../common/forms/FormFooter';
import { tzNormalizeDate } from '../../../../utils/date';

const selector = formValueSelector('ManualVoucherForm');

class ManualVoucherForm extends Component {
  static validateFields(values) {
    const errors = {};

    if (!values.items || !values.items.length)
      errors.itemsError = 'Debe ingresar un item';

    return errors;
  }

  constructor(props) {
    super(props);

    this.state = {
      isPerson: props.initialValues ? props.initialValues.isPerson : false,
      hasCreditPayment:
        props.initialValues.paymentType.value ===
        MANUAL_VOUCHER_PAYMENT_TYPE.CREDIT.value,
      flagCalculateTotalPriceAndAmount: false,
      affectedByTaxes: false,
      dueDateValue: props.initialValues.dueDate,
      currencyCodeValue: props.initialValues.currencyCode.value,
      voucherCodeValue:
        props.initialValues.voucherTypeId &&
        props.initialValues.voucherTypeId.voucherCode,
    };
  }

  componentDidUpdate() {
    this.calculateTotalAmount();
  }

  onChangeDetraction = ({ target: { value } }) =>
    this.calculateTotalToPay(this.props.totalAmount, value);

  onChangeIsPerson = ({ target: { checked } }) => {
    const { dispatchChange } = this.props;
    dispatchChange('ManualVoucherForm', 'businessId', null);
    dispatchChange('ManualVoucherForm', 'customerId', null);
    this.setState({ isPerson: checked });
  };

  onChangeIssueDate = (e, value) => {
    this.setState({ dueDateValue: value });
    if (!this.state.hasCreditPayment)
      this.props.dispatchChange('ManualVoucherForm', 'dueDate', value);
  };

  onChangeAffectedByTaxes = ({ target: { checked } }) => {
    this.setState({ affectedByTaxes: checked });
    this.activateCalculateTotal();
  };

  onChangeCurrencyCode = (option) => {
    this.setState({ currencyCodeValue: option.value });
    this.activateCalculateTotal();
  };

  onChangePaymentType = (option) => {
    if (option.value === MANUAL_VOUCHER_PAYMENT_TYPE.CASH.value)
      this.props.dispatchChange(
        'ManualVoucherForm',
        'dueDate',
        this.state.dueDateValue,
      );
    this.props.dispatchChange('ManualVoucherForm', 'installments', null);

    this.setState({
      hasCreditPayment:
        option.value === MANUAL_VOUCHER_PAYMENT_TYPE.CREDIT.value,
    });
  };

  onChangeVoucherType = (option) =>
    this.setState({ voucherCodeValue: option.voucherCode });

  onClickRemoveItem = (index, fields) => {
    fields.remove(index);
    this.activateCalculateTotal();
  };

  onSelectManualVoucher = ({
    business,
    customer,
    serviceType,
    paymentType,
    installments,
    currencyCode,
    affectedByTaxes,
    items,
    detraction,
  }) => {
    const { voucherTypeOptions } = this.props;

    const voucherTypeId = voucherTypeOptions.find(
      ({ voucherCode }) => voucherCode === SUNAT_CREDIT_NOTE_CODE,
    );

    const currencyCodeSelected = CURRENCY_CODE[currencyCode];

    const newItems = items.map(
      ({
        quantity,
        description: itemDescription,
        unitaryPrice,
        totalPrice,
      }) => ({
        quantity,
        description: itemDescription,
        unitaryPrice,
        totalPrice: numberFormatter({
          style: 'currency',
          value: totalPrice,
          currency: currencyCodeSelected.value,
        }),
      }),
    );

    this.resetForm({
      business,
      customer,
      voucherTypeId,
      serviceType,
      affectedByTaxes,
      currencyCode,
      paymentType,
      installments,
      items: newItems,
      detraction,
    });
  };

  onSelectPrivateService = ({
    contractPaymentType,
    amountBeforeDiscount,
    source,
    destination,
    creditPayment,
    business,
    customer,
  }) => {
    const { dispatchChange, voucherTypeOptions } = this.props;

    dispatchChange('ManualVoucherForm', 'previousManualVoucherId', null);

    // value 03 is considered as ticket
    let voucherTypeId = voucherTypeOptions.find(
      ({ voucherCode }) => voucherCode === SUNAT_TICKET_CODE,
    );

    // value 01 is considered as invoice
    if (
      contractPaymentType === CONTRACT_DOCUMENT_TYPE.FACTURA.value ||
      contractPaymentType === CONTRACT_DOCUMENT_TYPE.BOLETA_INTERNA.value
    )
      voucherTypeId = voucherTypeOptions.find(
        ({ voucherCode }) => voucherCode === SUNAT_INVOICE_CODE,
      );

    const currencyCodeSelected = CURRENCY_CODE.PEN;

    const items = [
      {
        quantity: 1,
        description: `Servicio de transporte de personal de ${source} a ${destination}`,
        unitaryPrice: amountBeforeDiscount,
      },
    ];

    this.resetForm({
      business,
      customer,
      voucherTypeId,
      serviceType: MANUAL_VOUCHER_SERVICE_TYPE.CONTRACT.value,
      affectedByTaxes: false,
      currencyCode: currencyCodeSelected.value,
      paymentType: creditPayment
        ? MANUAL_VOUCHER_PAYMENT_TYPE.CREDIT.value
        : MANUAL_VOUCHER_PAYMENT_TYPE.CASH.value,
      installments: null,
      items,
      detraction: null,
    });
  };

  resetForm = (data = null) => {
    const { dispatchChange } = this.props;

    const today = tzNormalizeDate();

    dispatchChange('ManualVoucherForm', 'isPerson', false);
    this.onChangeIsPerson({ target: { checked: false } });
    dispatchChange('ManualVoucherForm', 'voucherTypeId', null);
    dispatchChange('ManualVoucherForm', 'documentSeries', null);
    dispatchChange('ManualVoucherForm', 'documentCode', null);
    dispatchChange(
      'ManualVoucherForm',
      'serviceType',
      MANUAL_VOUCHER_SERVICE_TYPE.TICKET_SERVICE,
    );
    dispatchChange('ManualVoucherForm', 'issueDate', today);
    dispatchChange('ManualVoucherForm', 'affectedByTaxes', null);
    dispatchChange('ManualVoucherForm', 'currencyCode', CURRENCY_CODE.PEN);
    this.onChangeCurrencyCode(CURRENCY_CODE.PEN);
    dispatchChange('ManualVoucherForm', 'description', null);
    dispatchChange(
      'ManualVoucherForm',
      'paymentType',
      MANUAL_VOUCHER_PAYMENT_TYPE.CASH,
    );
    this.onChangePaymentType(MANUAL_VOUCHER_PAYMENT_TYPE.CASH);
    dispatchChange('ManualVoucherForm', 'dueDate', today);
    dispatchChange('ManualVoucherForm', 'itemsError', null);
    dispatchChange('ManualVoucherForm', 'items', []);
    dispatchChange('ManualVoucherForm', 'detraction', null);

    if (data) {
      const {
        business,
        customer,
        voucherTypeId,
        serviceType,
        affectedByTaxes,
        currencyCode,
        paymentType,
        installments,
        items,
        detraction,
      } = data;

      // Setting contractor data
      const businessId = business && {
        value: business.id,
        label: `${business.businessTaxId} - ${business.name}`,
        businessTaxId: business.businessTaxId,
        name: business.name,
        email: business.email,
        primaryContact: business.primaryContact,
        phone: business.phone,
        fax: business.fax,
        addressSummary: business.addressSummary,
        addressId: business.addressId,
        address: business.address,
      };

      const customerId = customer && {
        value: customer.id,
        label: customer.fullName,
        id: customer.id,
        firstName: customer.firstName,
        lastName: customer.lastName,
        fullName: customer.fullName,
        idDocumentNumber: customer.idDocumentNumber,
        gender: customer.gender,
        idCountryOfOrigin: customer.idCountryOfOrigin,
        identificationType: customer.identificationType,
        email: customer.email,
        mobilePhone: customer.mobilePhone,
        dob: customer.dob,
        discountCode: customer.discountCode,
      };

      const isPerson = !!customer;
      dispatchChange('ManualVoucherForm', 'isPerson', isPerson);
      this.onChangeIsPerson({ target: { checked: isPerson } });
      if (customerId)
        dispatchChange('ManualVoucherForm', 'customerId', customerId);
      if (businessId)
        dispatchChange('ManualVoucherForm', 'businessId', businessId);

      // Setting voucher data
      dispatchChange('ManualVoucherForm', 'voucherTypeId', voucherTypeId);
      this.onChangeVoucherType(voucherTypeId);
      dispatchChange(
        'ManualVoucherForm',
        'serviceType',
        MANUAL_VOUCHER_SERVICE_TYPE[serviceType],
      );

      // Setting payment data
      dispatchChange('ManualVoucherForm', 'affectedByTaxes', affectedByTaxes);
      this.onChangeAffectedByTaxes({ target: { checked: affectedByTaxes } });
      const currencyCodeSelected = CURRENCY_CODE[currencyCode];
      dispatchChange('ManualVoucherForm', 'currencyCode', currencyCodeSelected);
      this.onChangeCurrencyCode(currencyCodeSelected);
      const paymentTypeSelected = MANUAL_VOUCHER_PAYMENT_TYPE[paymentType];
      dispatchChange('ManualVoucherForm', 'paymentType', paymentTypeSelected);
      this.onChangePaymentType(paymentTypeSelected);
      dispatchChange('ManualVoucherForm', 'installments', installments);

      // Setting items data
      dispatchChange('ManualVoucherForm', 'items', items);
      dispatchChange('ManualVoucherForm', 'detraction', detraction);
      if (detraction)
        this.onChangeDetraction({ target: { value: detraction } });
    }
  };

  validateDate = (value, allValues) =>
    validateEndDate(value, allValues.issueDate);

  activateCalculateTotal = () =>
    this.setState({ flagCalculateTotalPriceAndAmount: true });

  calculateTotalToPay = (totalAmount, detractionPercent) => {
    const { dispatchChange } = this.props;

    const { currencyCodeValue } = this.state;

    const newTotalAmount = currencyToNumber(totalAmount);

    if (newTotalAmount >= 0) {
      const detractionAmount = newTotalAmount * (detractionPercent / 100);
      dispatchChange(
        'ManualVoucherForm',
        'detractionAmount',
        numberFormatter({
          style: 'currency',
          value: detractionAmount,
          currency: currencyCodeValue,
        }),
      );

      const totalAmountPayable = newTotalAmount - detractionAmount;
      dispatchChange(
        'ManualVoucherForm',
        'totalAmountPayable',
        numberFormatter({
          style: 'currency',
          value: totalAmountPayable,
          currency: currencyCodeValue,
        }),
      );
    }
  };

  calculateTotalAmount = () => {
    const {
      flagCalculateTotalPriceAndAmount,
      affectedByTaxes,
      currencyCodeValue,
    } = this.state;

    const { items, dispatchChange, detraction } = this.props;

    if (flagCalculateTotalPriceAndAmount) {
      let totalAmountWithoutTaxes = 0;

      const newItems = items.map((item) => {
        const totalPrice = (+item.quantity || 0) * (+item.unitaryPrice || 0);
        totalAmountWithoutTaxes += totalPrice;
        return {
          ...item,
          totalPrice: numberFormatter({
            style: 'currency',
            value: totalPrice,
            currency: currencyCodeValue,
          }),
        };
      });

      dispatchChange('ManualVoucherForm', 'items', newItems);
      dispatchChange(
        'ManualVoucherForm',
        'totalAmountWithoutTaxes',
        numberFormatter({
          style: 'currency',
          value: totalAmountWithoutTaxes,
          currency: currencyCodeValue,
        }),
      );

      let taxes = 0;
      let totalAmount = totalAmountWithoutTaxes;
      if (affectedByTaxes) {
        taxes = totalAmountWithoutTaxes * 0.18;
        totalAmount = +totalAmountWithoutTaxes + +taxes;
      }
      dispatchChange(
        'ManualVoucherForm',
        'taxes',
        numberFormatter({
          style: 'currency',
          value: taxes,
          currency: currencyCodeValue,
        }),
      );
      dispatchChange(
        'ManualVoucherForm',
        'totalAmount',
        numberFormatter({
          style: 'currency',
          value: totalAmount,
          currency: currencyCodeValue,
        }),
      );

      dispatchChange(
        'ManualVoucherForm',
        'totalAmountPayable',
        numberFormatter({
          style: 'currency',
          value: totalAmount,
          currency: currencyCodeValue,
        }),
      );
      this.calculateTotalToPay(totalAmount, detraction);

      this.setState({ flagCalculateTotalPriceAndAmount: false });
    }
  };

  render() {
    const { handleSubmit, loading } = this.props;

    const { hasCreditPayment, voucherCodeValue } = this.state;

    if (loading) return <Loader />;

    let customerField = (
      <BusinessInputGroup
        label="Empresa"
        labelRequired
        name="businessId"
        form="ManualVoucherForm"
        validate={[isRequired]}
        showDetails
      />
    );

    if (this.state.isPerson)
      customerField = (
        <>
          <CustomerInputGroup
            label="Cliente"
            labelRequired
            name="customerId"
            form="ManualVoucherForm"
            validate={[isRequired]}
            showDetails
          />
        </>
      );

    const contractorDataFields = (
      <>
        <h3>Datos del Contratante</h3>
        <FormGroup row>
          <FormItem label="">
            <Label>
              <Field
                name="isPerson"
                component="input"
                type="checkbox"
                onChange={this.onChangeIsPerson}
              />{' '}
              Es persona Natural
            </Label>
          </FormItem>
        </FormGroup>
        {customerField}
      </>
    );

    const baseDocumentRequired = [
      SUNAT_CREDIT_NOTE_CODE,
      SUNAT_DEBIT_NOTE_CODE,
    ].includes(voucherCodeValue);
    const baseDocumentValidation = [
      SUNAT_CREDIT_NOTE_CODE,
      SUNAT_DEBIT_NOTE_CODE,
    ].includes(voucherCodeValue)
      ? [isRequired]
      : null;
    const baseDocumentField = (
      <FormGroup row>
        <FormItem
          label="Comprobante Manual Previo"
          required={baseDocumentRequired}
        >
          <Field
            name="previousManualVoucherId"
            component={ManualVoucherSearchInput}
            placeholder="Comprobante Manual Previo"
            onSelectRow={this.onSelectManualVoucher}
            onClear={this.resetForm}
            props={{
              form: 'ManualVoucherForm',
            }}
            validate={baseDocumentValidation}
          />
        </FormItem>
      </FormGroup>
    );

    const descriptionField = [
      SUNAT_CREDIT_NOTE_CODE,
      SUNAT_DEBIT_NOTE_CODE,
    ].includes(voucherCodeValue) && (
      <FormGroup row>
        <FormItem label="Motivo o Sustento" required>
          <Field
            name="description"
            component={TextInput}
            type="textarea"
            placeholder="Motivo o Sustento"
            validate={[isRequired]}
          />
        </FormItem>
      </FormGroup>
    );

    const voucherDataFields = (
      <>
        <h3>Datos del Comprobante</h3>
        <FormGroup row>
          <FormItem label="Tipo de Comprobante" required>
            <Field
              name="voucherTypeId"
              component={VoucherTypeSelect}
              onChange={this.onChangeVoucherType}
              isClearable={false}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="N° Comprobante">
            <Row>
              <Col lg={3} md={4} sm={5}>
                <Field
                  name="documentSeries"
                  id="documentSeries"
                  component={TextInput}
                  showToolTip
                  type="text"
                  placeholder="Serie"
                  disabled
                />
              </Col>
              <Col lg={5} md={7} sm={6}>
                <Field
                  name="documentCode"
                  id="documentCode"
                  component={TextInput}
                  showToolTip
                  type="text"
                  placeholder="Correlativo"
                  disabled
                  validate={[validateIntegerNumber]}
                />
              </Col>
            </Row>
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Tipo de Servicio" required>
            <Field
              name="serviceType"
              component={Select}
              options={enumToSelectOptions(MANUAL_VOUCHER_SERVICE_TYPE)}
              isClearable={false}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Fecha de Expedición" required>
            <Field
              name="issueDate"
              component={DatePicker}
              onChange={this.onChangeIssueDate}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        {descriptionField}
      </>
    );

    const paymentDataFields = (
      <>
        <h3>Datos del Pago</h3>
        <FormGroup row>
          <FormItem label="">
            <Label>
              <Field
                name="affectedByTaxes"
                component="input"
                type="checkbox"
                onChange={this.onChangeAffectedByTaxes}
              />{' '}
              Afecta a Impuestos
            </Label>
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Moneda" required>
            <Field
              name="currencyCode"
              component={Select}
              options={enumToSelectOptions(CURRENCY_CODE)}
              onChange={this.onChangeCurrencyCode}
              isClearable={false}
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row>
          <FormItem label="Método de Pago" required>
            <Row>
              <Col lg={3} md={4} sm={12}>
                <Field
                  name="paymentType"
                  component={Select}
                  options={enumToSelectOptions(MANUAL_VOUCHER_PAYMENT_TYPE)}
                  onChange={this.onChangePaymentType}
                  isClearable={false}
                  validate={[isRequired]}
                />
              </Col>
              <Col lg={2} md={3} sm={6}>
                <Field
                  name="installments"
                  id="installments"
                  component={TextInput}
                  showToolTip
                  disabled={!hasCreditPayment}
                  type="text"
                  placeholder="Cuotas"
                  validate={[validateIntegerNumber]}
                />
              </Col>
              <Col lg={4} md={5} sm={6}>
                <Field
                  name="dueDate"
                  id="dueDate"
                  component={DatePicker}
                  disabled={!hasCreditPayment}
                  placeholder="Fecha de Expiración"
                  showToolTip
                  validate={[isRequired, this.validateDate]}
                />
              </Col>
            </Row>
          </FormItem>
        </FormGroup>
      </>
    );

    const detractionFields = voucherCodeValue === SUNAT_INVOICE_CODE && (
      <>
        <FormGroup row>
          <FormItem label="Porcentaje Detracción">
            <Field
              name="detraction"
              component={TextInput}
              type="text"
              placeholder="Porcentaje Detracción"
              onChange={this.onChangeDetraction}
              append={<InputGroupText>%</InputGroupText>}
              validate={[validateNumber]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row className="align-items-center">
          <FormItem label="Monto Detracción">
            <Field
              name="detractionAmount"
              component={TextInput}
              type="text"
              placeholder="Monto Detracción"
              disabled
            />
          </FormItem>
        </FormGroup>
        <FormGroup row className="align-items-center">
          <FormItem label="Total a Pagar" required>
            <Field
              name="totalAmountPayable"
              component={TextInput}
              placeholder="Total a Pagar"
              type="text"
              size="lg"
              disabled
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
      </>
    );

    const itemsDataFields = (
      <>
        <h4>Items a Facturar</h4>
        <Field name="itemsError" component={TextInput} type="hidden" />
        <DynamicForm
          name="items"
          title="Item"
          columns={generateManualVoucherFormColumns(
            this.activateCalculateTotal,
            this.activateCalculateTotal,
          )}
          onClickRemove={this.onClickRemoveItem}
        />
        <FormGroup row className="align-items-center">
          <FormItem label="Total Gravada" required>
            <Field
              name="totalAmountWithoutTaxes"
              component={TextInput}
              placeholder="Total Gravada"
              type="text"
              disabled
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        <FormGroup row className="align-items-center">
          <FormItem label="Total IGV 18%">
            <Field
              name="taxes"
              component={TextInput}
              placeholder="Total IGV 18%"
              type="text"
              disabled
            />
          </FormItem>
        </FormGroup>
        <FormGroup row className="align-items-center">
          <FormItem label="Importe Total" required>
            <Field
              name="totalAmount"
              component={TextInput}
              placeholder="Importe Total"
              type="text"
              size="lg"
              disabled
              validate={[isRequired]}
            />
          </FormItem>
        </FormGroup>
        {detractionFields}
      </>
    );

    return (
      <>
        <div className="text-center border-bottom pb-2 mb-3">
          <PrivateServiceSearchButton
            title="Completar con datos de Contrato"
            getRowData={this.onSelectPrivateService}
            size="sm"
          />
        </div>
        <Form onSubmit={handleSubmit}>
          {baseDocumentField}
          {contractorDataFields}
          <hr />
          {voucherDataFields}
          <hr />
          {paymentDataFields}
          {itemsDataFields}
          <FormFooter />
        </Form>
      </>
    );
  }
}

ManualVoucherForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatchChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      description: PropTypes.string,
      unitaryPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      totalPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
  totalAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  detraction: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  initialValues: PropTypes.shape({
    dueDate: PropTypes.string,
    currencyCode: optionPropTypes,
    paymentType: optionPropTypes,
    isPerson: PropTypes.bool,
    voucherTypeId: optionPropTypes,
  }),
  voucherTypeOptions: optionsPropTypes,
};

ManualVoucherForm.defaultProps = {
  initialValues: null,
  items: [],
  totalAmount: 0,
  detraction: 0,
  voucherTypeOptions: [],
};

const mapStateToProps = (state) => ({
  loading: !state.AccountingUnit.ManualVoucher.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  items: selector(state, 'items'),
  totalAmount: selector(state, 'totalAmount'),
  detraction: selector(state, 'detraction'),
  voucherTypeOptions: state.AccountingUnit.VoucherType.getIn([
    'all',
    'content',
    'content',
  ]).map((voucherType) => ({
    value: voucherType.id,
    label: voucherType.name,
    voucherCode: voucherType.voucherCode,
  })),
});

const mapDispatchToProps = {
  dispatchChange: change,
};

const formComponent = reduxForm({
  form: 'ManualVoucherForm',
  validate: ManualVoucherForm.validateFields,
})(ManualVoucherForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
