import Immutable from 'immutable';
import {
  GET_ITEMS_TO_ITINERARY,
  CLEAR_ITEMS_TO_ITINERARY,
  FLAG_ITEM_TO_ITINERARY_ACTIVITY,
  UPDATE_ITEM_TO_ITINERARY,
  DELETE_ITEM_FROM_ITINERARY,
} from '../../../actions/types/cargo';

const INITIAL_STATE = Immutable.Map({
  current: Immutable.Map({
    activity: Immutable.List(),
    items: Immutable.Map(),
  }),
});

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FLAG_ITEM_TO_ITINERARY_ACTIVITY:
      if (payload) {
        return state.setIn(
          ['current', 'activity'],
          state.getIn(['current', 'activity']).push(payload),
        );
      }

      return state.setIn(
        ['current', 'activity'],
        state.getIn(['current', 'activity']).pop(),
      );
    case GET_ITEMS_TO_ITINERARY:
      return state.setIn(['current', 'items'], Immutable.Map(payload));
    case CLEAR_ITEMS_TO_ITINERARY:
      return state.setIn(
        ['current', 'items'],
        INITIAL_STATE.getIn(['current', 'items']),
      );
    case UPDATE_ITEM_TO_ITINERARY:
      return state.updateIn(['current', 'items', 'content'], (content) =>
        content.map((item) =>
          item.correlation === payload.correlation ? payload : item,
        ),
      );
    case DELETE_ITEM_FROM_ITINERARY:
      return state.updateIn(['current', 'items', 'content'], (content) =>
        content.filter((item) => item.correlation !== payload),
      );

    default:
      return state;
  }
};
