import React from 'react';
import { padStart } from 'lodash';
import { PEN_SYMBOL } from '../../../../../config/constants';
import { DATE_FORMAT } from '../../../../../config/locale';
import { tzNormalizeDate } from '../../../../../utils/date';
import { CARGO_ITEM_PATH } from '../../../../../config/paths';
import { CARGO_ITEMS_COLUMNS } from '../../../../../config/columns';
import { filterMethodCaseInsensitive } from '../../../../../utils/filter-methods';
import Table from '../../../../common/Table';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import parcelBasicInformationPropTypes from '../proptypes/ParcelPropTypes';

const ParcelBasicInformationResource = ({
  cargoItemList,
  cargoDelivery,
  detractionAmount,
  salePriceWithDetraction,
  customer,
  business,
  voucherTypeName,
  documentSeries,
  documentCode,
  sourceCityName,
  destinationCityName,
  createDate,
  senderCustomerFullName,
  senderCustomer,
  consigneeCustomerFullName,
  consigneeCustomer,
  paymentMethodName,
  listPrice,
  salePrice,
  attachedDocument,
}) => {
  const items = (
    <div>
      <h5>Items</h5>
      <Table
        columns={CARGO_ITEMS_COLUMNS}
        data={cargoItemList}
        manual={false}
        filterable
        defaultPageSize={10}
        defaultFilterMethod={filterMethodCaseInsensitive}
        modelPath={CARGO_ITEM_PATH}
        navigateToModelOnRowClick
        openPathInNewTab
      />
    </div>
  );

  const delivery = cargoDelivery ? (
    <>
      <h5>Delivery</h5>
      <ResourceProperty label="Distrito:">
        {cargoDelivery.district.name}
      </ResourceProperty>
      <ResourceProperty label="Dirección:">
        {cargoDelivery.address}
      </ResourceProperty>
    </>
  ) : null;

  const detraction =
    detractionAmount !== null ? (
      <>
        <ResourceProperty label="Detracción:">
          {detractionAmount}
        </ResourceProperty>
        <ResourceProperty label="Precio Total con Detracción:">
          {salePriceWithDetraction}
        </ResourceProperty>
      </>
    ) : null;

  let client = null;
  if (customer !== null) {
    client = (
      <ResourceProperty label="Cliente:">
        {customer.fullName}
        {' - '}
        {customer.identificationType.name} {customer.idDocumentNumber}
      </ResourceProperty>
    );
  } else {
    client = (
      <ResourceProperty label="Empresa:">
        {business.name}
        {' - RUC '}
        {business.businessTaxId}
      </ResourceProperty>
    );
  }

  return (
    <>
      <ResourceProperty label="Tipo de Comprobante:">
        {voucherTypeName}
      </ResourceProperty>
      <ResourceProperty label="Número de Comprobante:">
        {documentSeries}-{padStart(documentCode, 7)}
      </ResourceProperty>
      <ResourceProperty label="Origen:">{sourceCityName}</ResourceProperty>
      <ResourceProperty label="Destino:">
        {destinationCityName}
      </ResourceProperty>
      <ResourceProperty label="Creado el:">
        {tzNormalizeDate({
          date: createDate,
          format: DATE_FORMAT,
        })}
      </ResourceProperty>
      <ResourceProperty label="Remitente:">
        {senderCustomerFullName}
        {' - '}
        {senderCustomer.identificationType.name}{' '}
        {senderCustomer.idDocumentNumber}
      </ResourceProperty>
      <ResourceProperty label="Consignado:">
        {consigneeCustomerFullName}
        {' - '}
        {consigneeCustomer.identificationType.name}{' '}
        {consigneeCustomer.idDocumentNumber}
      </ResourceProperty>
      {client}
      <ResourceProperty label="Método de Pago:">
        {paymentMethodName}
      </ResourceProperty>
      <ResourceProperty label="Precio Total de Lista:">
        {PEN_SYMBOL} {listPrice.toFixed(2)}
      </ResourceProperty>
      <ResourceProperty label="Precio Total de Venta:">
        {PEN_SYMBOL} {salePrice.toFixed(2)}
      </ResourceProperty>
      <ResourceProperty label="Documento adjunto">
        {attachedDocument || '-'}
      </ResourceProperty>
      {detraction}
      {delivery}
      {items}
    </>
  );
};

ParcelBasicInformationResource.propTypes = parcelBasicInformationPropTypes;

export default ParcelBasicInformationResource;
