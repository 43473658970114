import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  clearItemToItinerary,
  getCargoItemToItinerary,
} from '../../../../actions';
import { DEFAULT_PAGE_SIZE } from '../../../../config/constants';
import Table from '../../../common/Table';

export class ItemToItineraryResult extends Component {
  componentWillUnmount() {
    this.props.dispatchClearItemToItinerary();
  }

  render() {
    const {
      loading,
      cargoItems,
      searchFormValues,
      dispatchGetCargoItemToItinerary,
      columns,
    } = this.props;

    const data = cargoItems.get('content') || [];
    const pages = cargoItems.get('totalPages') || null;
    const defaultPageSize = cargoItems.get('size') || DEFAULT_PAGE_SIZE;

    return (
      <div className="mt-3">
        <Table
          columns={columns}
          data={data}
          pages={pages}
          defaultPageSize={defaultPageSize}
          loading={loading}
          fetchData={dispatchGetCargoItemToItinerary}
          params={searchFormValues}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ CargoUnit }) => ({
  loading: !CargoUnit.ItemToItinerary.getIn(['current', 'activity']).isEmpty(),
  cargoItems: CargoUnit.ItemToItinerary.getIn(['current', 'items']),
});

const mapDispatchToProps = {
  dispatchGetCargoItemToItinerary: getCargoItemToItinerary,
  dispatchClearItemToItinerary: clearItemToItinerary,
};

ItemToItineraryResult.propTypes = {
  cargoItems: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
  dispatchClearItemToItinerary: PropTypes.func.isRequired,
  searchFormValues: PropTypes.shape({
    sourceLocationId: PropTypes.number,
    destinationCityId: PropTypes.number,
    createDate: PropTypes.string,
    itineraryId: PropTypes.number,
  }).isRequired,
  dispatchGetCargoItemToItinerary: PropTypes.func.isRequired,
  columns: PropTypes.instanceOf(Array).isRequired,
};

ItemToItineraryResult.defaultProps = {
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ItemToItineraryResult);
