import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ButtonToolbar } from 'reactstrap';
import Loader from '../../../common/Loader';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import { CARGO_PATH, INTERNAL_TRANSFER_PATH } from '../../../../config/paths';
import {
  clearCargoItemsDetail,
  getCargoItemDetail,
  getInternalCargoItemDetail,
} from '../../../../actions';
import Table from '../../../common/Table';
import {
  CARGO_ITEM_MOVEMENT_COLUMNS,
  CARGO_ITEM_PARCEL_DETAIL_COLUMNS,
} from '../../../../config/columns';
import PrintUnitCargoTicketButton from './PrintUnitCargoTicketButton';
import ChangeCargoItemStatusDropdownButton from './ChangeCargoItemStatusDropdownButton';
import CargoItemDetailBasicInformationResource from './resources/CargoItemDetailBasicInformationResource';
import ChangeInternalCargoItemStatusDropdownButton from './ChangeInternalCargoItemStatusDropdownButton';
import NoDataResource from '../../../common/resource/NoDataResource';

export const CargoItemDetail = (props) => {
  const {
    breadcrumbs,
    dispatchGetCargoItemDetail,
    dispatchGetInternalCargoItemDetail,
    dispatchClearCargoItemDetail,
    loading,
    match: {
      params: { id: cargoItemId },
    },
    cargoItemDetail,
    location,
  } = props;

  const urlContainsInternal = location.pathname.includes('internal');

  useEffect(() => {
    if (!urlContainsInternal) {
      dispatchGetCargoItemDetail({ cargoItemId });
    } else {
      dispatchGetInternalCargoItemDetail({ cargoItemId });
    }

    return () => {
      dispatchClearCargoItemDetail();
    };
  }, [cargoItemId, location.pathname]);

  let content = null;

  const toolbar = (
    <ButtonToolbar className="pull-right">
      <div>
        {!urlContainsInternal && (
          <ChangeCargoItemStatusDropdownButton
            cargoItemId={cargoItemDetail.get('cargoItemId')}
          />
        )}
        {urlContainsInternal && (
          <ChangeInternalCargoItemStatusDropdownButton
            cargoItemId={cargoItemDetail.get('cargoItemId')}
          />
        )}

        {'  '}
        {cargoItemDetail.get('cargoItemId') && (
          <PrintUnitCargoTicketButton cargoItemDetail={cargoItemDetail} />
        )}
      </div>
    </ButtonToolbar>
  );

  const generateParcelItem = (cargoItemDetailParam) => [
    {
      id: cargoItemDetailParam.parcelId,
      parcelCorrelation: cargoItemDetailParam.parcelCorrelation,
      createUserFullName: cargoItemDetailParam.createUserFullName,
      createUserEmail: cargoItemDetailParam.createUserEmail,
      parcelCreateDate: cargoItemDetailParam.parcelCreateDate,
      parcelPaymentStatus: cargoItemDetailParam.parcelPaymentStatus,
    },
  ];

  if (loading) {
    content = <Loader />;
  } else if (cargoItemDetail.isEmpty()) {
    if (!urlContainsInternal) {
      content = <NoDataResource returnPage={CARGO_PATH} />;
    } else {
      content = <NoDataResource returnPage={INTERNAL_TRANSFER_PATH} />;
    }
  } else {
    const cargoItemDetailJson = cargoItemDetail.toJSON();

    const tableMovements = (
      <Table
        columns={CARGO_ITEM_MOVEMENT_COLUMNS}
        data={cargoItemDetailJson.movementList}
        showPagination={false}
        defaultPageSize={cargoItemDetailJson.movementList.length}
      />
    );

    const tableParcelDetail = (
      <Table
        columns={CARGO_ITEM_PARCEL_DETAIL_COLUMNS}
        data={generateParcelItem(cargoItemDetailJson)}
        modelPath={CARGO_PATH}
        showPagination={false}
        navigateToModelOnRowClick
        defaultPageSize={generateParcelItem(cargoItemDetailJson).length}
      />
    );
    content = (
      <>
        <CargoItemDetailBasicInformationResource {...cargoItemDetailJson} />

        {!urlContainsInternal && <h3>Detalle de Boleta de Carga</h3>}
        {!urlContainsInternal && tableParcelDetail}
        <h3>Detalle de Escaneos</h3>
        {tableMovements}
      </>
    );
  }

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Encomienda"
      content={content}
      toolbar={toolbar}
    />
  );
};

CargoItemDetail.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchClearCargoItemDetail: PropTypes.func.isRequired,
  dispatchGetCargoItemDetail: PropTypes.func.isRequired,
  dispatchGetInternalCargoItemDetail: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  cargoItemDetail: PropTypes.instanceOf(Immutable.Map).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
};

CargoItemDetail.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ CargoUnit }, ownProps) => {
  const urlContainsInternal = ownProps.location.pathname.includes('internal');
  const breadcrumbs = urlContainsInternal
    ? [
        ...CargoUnit.UnitHome.get('breadcrumbs'),
        {
          text: 'Translados Internos',
          href: INTERNAL_TRANSFER_PATH,
        },
        {
          text: 'Encomienda',
          href: '',
        },
      ]
    : [
        ...CargoUnit.UnitHome.get('breadcrumbs'),
        {
          text: 'Carga',
          href: CARGO_PATH,
        },
        {
          text: 'Encomienda',
          href: '',
        },
      ];

  return {
    breadcrumbs,
    cargoItemDetail: CargoUnit.CargoItem.getIn(['current', 'detail']),
    loading: !CargoUnit.CargoItem.getIn(['current', 'activity']).isEmpty(),
  };
};

const mapDispatchToProps = {
  dispatchGetCargoItemDetail: getCargoItemDetail,
  dispatchGetInternalCargoItemDetail: getInternalCargoItemDetail,
  dispatchClearCargoItemDetail: clearCargoItemsDetail,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CargoItemDetail),
);
