import PropTypes from 'prop-types';

const paymentMethodPropTypes = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  transactionCode: PropTypes.string,
  description: PropTypes.string,
  createDate: PropTypes.number,
  lastUpdate: PropTypes.number,
});

const securityProfilePropTypes = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
});

const paymentMethodForSecurityProfilePropTypes = PropTypes.shape({
  id: PropTypes.number,
  paymentMethod: paymentMethodPropTypes,
  securityProfile: securityProfilePropTypes,
});

export default paymentMethodForSecurityProfilePropTypes;
