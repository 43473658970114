import React from 'react';
import { Field } from 'redux-form';
import { string, number, PropTypes } from 'prop-types';
import { Button, FormGroup, Form } from 'reactstrap';
import Select from '../../../common/forms/select/Select';
import FormItem from '../../../common/forms/FormItem';
import { isRequired } from '../../../../utils/validators';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import FormFooter from '../../../common/forms/FormFooter';

const ItemDetailsModal = ({ item, options, handleProcess, onCancel }) => (
  <Form onSubmit={handleProcess}>
    {item.internalCargoItem === true && (
      <ResourceProperty label="ID de Item Interno:">{item.id}</ResourceProperty>
    )}
    {item.internalCargoItem === false && (
      <ResourceProperty label="ID de Item:">{item.id}</ResourceProperty>
    )}
    <ResourceProperty label="Cantidad:">{item.quantity}</ResourceProperty>
    <ResourceProperty label="Descripción:">{item.description}</ResourceProperty>
    <ResourceProperty label="Peso:">{item.weight}</ResourceProperty>
    <ResourceProperty label="Correlativo:">{item.correlation}</ResourceProperty>
    <ResourceProperty label="Destino:">
      {item.destinationCityName}
    </ResourceProperty>
    <hr />
    <FormGroup row>
      <FormItem label="Ubicacion de Destino" required>
        <Field
          name="selectLocation"
          component={Select}
          type="text"
          placeholder="Seleccione un destino"
          validate={[isRequired]}
          options={options}
        />
      </FormItem>
    </FormGroup>
    <FormFooter saveButtonColor="secondary" saveButtonIcon={null}>
      <Button color="primary" onClick={onCancel}>
        Cancelar
      </Button>
    </FormFooter>
  </Form>
);

const itemPropTypes = {
  id: number,
  quantity: number,
  description: string,
  weight: number,
  correlation: string,
  destination: string,
};

ItemDetailsModal.propTypes = {
  item: PropTypes.shape(itemPropTypes).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  handleProcess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ItemDetailsModal;
