import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, change } from 'redux-form';
import { Button, Col, Form, FormGroup } from 'reactstrap';
import Immutable from 'immutable';
import { toastr } from 'react-redux-toastr';
import FormItem from '../../../common/forms/FormItem';
import { isRequired } from '../../../../utils/validators';
import {
  clearItemToItinerary,
  clearItinerary,
  deleteItemFromItinerary,
  getCargoItemByItinerary,
  getItineraryForCounter,
  getUserAgencies,
  putRemoveItemFromItinerary,
} from '../../../../actions';
import ItinerarySearchInput from '../../../common/forms/input/ItinerarySearchInput';
import UnloadingCargoResult from './UnloadingCargoResult';
import { generateUnloadingCargoItemsColumns } from '../../../../config/columns';
import { DEFAULT_QUERY } from '../../../../config/queries';
import Select from '../../../common/forms/select/Select';
import { optionsPropTypes } from '../../../common/forms/select/SelectPropTypes';

export const getUniqueLocations = (itineraryCounter) => {
  const uniqueLocations = [];
  const locationSet = new Set();

  itineraryCounter.get('itineraryScheduleSegmentList').forEach((segment) => {
    const { destinationLocation } = segment.segment;

    if (!locationSet.has(destinationLocation.id)) {
      uniqueLocations.push({
        id: destinationLocation.id,
        name: destinationLocation.name,
      });
      locationSet.add(destinationLocation.id);
    }
  });

  return uniqueLocations;
};

export class UnloadingCargoForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      destinationLocationId: null,
      itineraryId: 0,
      agenciesToShow: [],
    };
  }

  componentDidMount() {
    const { user, dispatchGetUserAgencies } = this.props;
    if (user) {
      dispatchGetUserAgencies({ userId: user.id });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { itineraryCounter, loadingItinerary } = this.props;
    const { itineraryId } = this.state;

    if (
      (itineraryId !== prevState.itineraryId ||
        itineraryCounter !== prevProps.itineraryCounter) &&
      loadingItinerary
    ) {
      this.verifyLocationWithAgencies();
    }
  }

  componentWillUnmount() {
    const { dispatchClearItemToItinerary, dispatchClearItinerary } = this.props;
    dispatchClearItemToItinerary();
    dispatchClearItinerary();
  }

  onHandleSubmit = ({ destinationLocation }) => {
    const { dispatchGetCargoItemByItinerary, dispatchClearItemToItinerary } =
      this.props;
    const { itineraryId } = this.state;

    this.setState({
      destinationLocationId: destinationLocation.value,
      itineraryId,
    });

    dispatchClearItemToItinerary();
    dispatchGetCargoItemByItinerary({
      itineraryId,
      ...DEFAULT_QUERY,
    });
  };

  onRemoveItemFromItinerary = async (
    correlation,
    cargoItemId,
    internalCargoItem,
  ) => {
    const {
      dispatchPutRemoveItemFromItinerary,
      dispatchDeleteItemFromItinerary,
    } = this.props;
    const { itineraryId, destinationLocationId } = this.state;
    const removed = await dispatchPutRemoveItemFromItinerary({
      cargoItemId,
      itineraryId,
      locationId: destinationLocationId,
      internalCargoItem,
    });
    if (removed) {
      dispatchDeleteItemFromItinerary(correlation);
    }
  };

  handleSelectItinerary = ({ itineraryId }) => {
    this.setState({ itineraryId });
    this.props.dispatchGetItineraryForCounter(itineraryId);
  };

  handleShowItinerarySearch = () => {
    const { dispatchClearItemToItinerary, dispatchChange } = this.props;
    dispatchClearItemToItinerary();
    dispatchChange('UnloadingCargoForm', 'itineraryId', '');
    this.setState({ agenciesToShow: [] });
  };

  verifyLocationWithAgencies = () => {
    const { agencyOptions, itineraryCounter } = this.props;
    const uniqueLocations = getUniqueLocations(itineraryCounter);

    let newAgenciesToShow = [];

    if (uniqueLocations.length !== 0) {
      newAgenciesToShow = agencyOptions.filter((agency) =>
        uniqueLocations.some((location) => agency.value === location.id),
      );

      if (newAgenciesToShow.length === 0) {
        toastr.error(
          'Error',
          'No puedes descargar de este itinerario, ninguna de sus agencias coincide con los destinos.',
        );
        return;
      }
    }

    this.setState({ agenciesToShow: newAgenciesToShow });
  };

  render() {
    const { itineraryId, agenciesToShow } = this.state;
    const { loadingAgencies, handleSubmit } = this.props;

    return (
      <>
        <Form onSubmit={handleSubmit(this.onHandleSubmit)}>
          <FormGroup row>
            <FormItem label="Itinerario" required>
              <Field
                name="itineraryId"
                component={ItinerarySearchInput}
                placeholder="Itinerario"
                handleSelectItinerary={this.handleSelectItinerary}
                handleShowItinerarySearch={this.handleShowItinerarySearch}
                validate={[isRequired]}
                props={{
                  form: 'UnloadingCargoForm',
                }}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Destino" required>
              <Field
                name="destinationLocation"
                component={Select}
                isLoading={loadingAgencies}
                options={agenciesToShow}
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <Col className="flex row-reverse">
              <Button type="submit" color="primary">
                Buscar
              </Button>
            </Col>
          </FormGroup>
        </Form>
        <h5>Lista de Items</h5>
        <UnloadingCargoResult
          columns={generateUnloadingCargoItemsColumns(
            this.onRemoveItemFromItinerary,
          )}
          searchFormValues={{ itineraryId }}
        />
      </>
    );
  }
}

UnloadingCargoForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loadingAgencies: PropTypes.bool,
  agencyOptions: optionsPropTypes,
  itineraryCounter: PropTypes.instanceOf(Immutable.Map).isRequired,
  loadingItinerary: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    customer: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dispatchChange: PropTypes.func.isRequired,
  dispatchClearItemToItinerary: PropTypes.func.isRequired,
  dispatchGetCargoItemByItinerary: PropTypes.func.isRequired,
  dispatchGetUserAgencies: PropTypes.func.isRequired,
  dispatchPutRemoveItemFromItinerary: PropTypes.func.isRequired,
  dispatchDeleteItemFromItinerary: PropTypes.func.isRequired,
  dispatchGetItineraryForCounter: PropTypes.func.isRequired,
  dispatchClearItinerary: PropTypes.func.isRequired,
};

UnloadingCargoForm.defaultProps = {
  loadingAgencies: false,
  loadingItinerary: false,
  agencyOptions: [],
};

const mapStateToProps = ({
  HumanResourcesUnit,
  ItineraryUnit,
  authentication,
}) => ({
  loadingAgencies: HumanResourcesUnit.Agency.getIn(['all', 'loading']),
  agencyOptions: HumanResourcesUnit.Agency.getIn([
    'all',
    'content',
    'content',
  ]).map((agency) => ({
    value: agency.locationId,
    label: agency.name,
  })),
  itineraryCounter:
    ItineraryUnit.Itinerary.getIn(['current', 'content']) || undefined,
  loadingItinerary: !ItineraryUnit.Itinerary.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  user: authentication.get('user') || undefined,
});

const mapDispatchToProps = {
  dispatchChange: change,
  dispatchGetCargoItemByItinerary: getCargoItemByItinerary,
  dispatchClearItemToItinerary: clearItemToItinerary,
  dispatchGetUserAgencies: getUserAgencies,
  dispatchPutRemoveItemFromItinerary: putRemoveItemFromItinerary,
  dispatchDeleteItemFromItinerary: deleteItemFromItinerary,
  dispatchGetItineraryForCounter: getItineraryForCounter,
  dispatchClearItinerary: clearItinerary,
};

const formComponent = reduxForm({
  form: 'UnloadingCargoForm',
})(UnloadingCargoForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
