import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  getItineraryForCounter,
  clearItinerary,
  clearDrivers,
  getListDriversForItinerary,
  getCargoItemByItinerary,
  clearItemToItinerary,
  getPassengerFlowForItinerary,
  clearPassengerFlowForItinerary,
} from '../../../../../actions/index';
import Loader from '../../../../common/Loader';
import ResourceProperty from '../../../../common/resource/ResourceProperty';
import { ITINERARY_FOR_COUNTER_PATH } from '../../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../../common/resource/proptypes/CommonPropTypes';
import IsActive from '../../../../common/cells/IsActive';
import { Table } from '../../../../common/Table';
import { DATE_TIME_FORMAT } from '../../../../../config/locale';
import ItineraryForCounterToolbar from './ItineraryForCounterToolbar';
import Modal from '../../../../common/modal/Modal';
import TimetableTemplate from '../TimetableTemplate';
import {
  ITINERARY_SCHEDULE_WITHOUT_SEGMENT_LIST_MESSAGE,
  ITINERARY_WITHOUT_CABIN_CREW_INFO_MESSAGE,
  ITINERARY_WITHOUT_CARGO_MESSAGE,
  ITINERARY_WITHOUT_DRIVER_INFO_MESSAGE,
  ITINERARY_WITHOUT_PASSENGER_FLOW_MESSAGE,
} from '../../../../../config/messages';
import NoDataResource from '../../../../common/resource/NoDataResource';
import Content from '../../../../layout/Content';
import Alert from '../../../../common/informative/Alert';
import {
  DEFAULT_PAGE_SIZE,
  ITINERARY_TYPE,
} from '../../../../../config/constants';
import { tzNormalizeDate } from '../../../../../utils/date';
import { DEFAULT_QUERY } from '../../../../../config/queries';
import { CARGO_ITINERARY_COLUMNS } from '../../../../../config/columns';

export class Itinerary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      modalTitle: '',
      modalBody: null,
    };
  }

  componentDidMount() {
    const {
      dispatchGetItineraryForCounter,
      dispatchGetListDriversForItinerary,
      dispatchGetCargoItemByItinerary,
      dispatchGetPassengerFlowForItinerary,
      match: {
        params: { id: itineraryId },
      },
    } = this.props;

    dispatchGetItineraryForCounter(itineraryId);
    dispatchGetListDriversForItinerary({ itineraryId });
    dispatchGetCargoItemByItinerary({ itineraryId, ...DEFAULT_QUERY });
    dispatchGetPassengerFlowForItinerary({ itineraryId });
  }

  componentWillUnmount() {
    const {
      dispatchClearItinerary,
      dispatchClearDrivers,
      dispatchClearItemToItinerary,
      dispatchClearPassengerFlowForItinerary,
    } = this.props;
    dispatchClearItinerary();
    dispatchClearDrivers();
    dispatchClearItemToItinerary();
    dispatchClearPassengerFlowForItinerary();
  }

  renderPassengerFlowModal = (type, passengerFlow) => {
    const title = `${type === 'boarding' ? 'Embarque' : 'Desembarque'}: ${
      passengerFlow.locationName
    } (${passengerFlow[`${type}Passengers`]})`;

    const ticketsData = passengerFlow[`${type}Tickets`];
    const body = (
      <Table
        columns={[
          {
            Header: 'Boleto',
            accessor: 'ticketCode',
            className: 'text-center',
          },
          {
            Header: 'Pasajero',
            accessor: 'customer.fullName',
            className: 'text-center',
          },
          {
            Header: 'Asiento',
            accessor: 'seatReservation.seat.seatNumber',
            className: 'text-center',
          },
        ]}
        data={ticketsData}
        defaultPageSize={ticketsData.length}
        showPagination={false}
      />
    );

    this.setState({ showModal: true, modalTitle: title, modalBody: body });
  };

  renderPassengersFlow = (type, passengerFlow) => {
    let passengers = 0;

    if (passengerFlow[`${type}Passengers`]) {
      passengers = (
        <div
          role="button"
          onClick={() => this.renderPassengerFlowModal(type, passengerFlow)}
          onKeyPress={() => this.renderPassengerFlowModal(type, passengerFlow)}
          tabIndex="0"
          className="clickable"
        >
          <strong>
            <u>{passengerFlow[`${type}Passengers`]}</u>
          </strong>
        </div>
      );
    }
    return passengers;
  };

  render() {
    const {
      breadcrumbs,
      itinerary,
      loading,
      drivers,
      loadingCargoItems,
      cargoItems,
      passengerFlow,
      dispatchGetCargoItemByItinerary,
      match: {
        params: { id: itineraryId },
      },
    } = this.props;

    const { showModal, modalTitle, modalBody } = this.state;

    let content;
    let toolbar;

    if (loading) {
      content = <Loader />;
    } else if (itinerary.isEmpty()) {
      content = <NoDataResource returnPage={ITINERARY_FOR_COUNTER_PATH} />;
    } else {
      const itineraryResource = (
        <Fragment>
          <h3>Información Básica</h3>
          <ResourceProperty label="Id:">{itinerary.get('id')}</ResourceProperty>
          <ResourceProperty label="ID de Correlación Civa:">
            {itinerary.get('itineraryCorrelationId') || '-'}
          </ResourceProperty>
          <ResourceProperty label="Ruta:">
            {itinerary.get('routeName')}
          </ResourceProperty>
          <ResourceProperty label="Circuito:">
            {itinerary.get('circuitName')}
          </ResourceProperty>
          <ResourceProperty label="Tipo de Servicio:">
            {itinerary.get('serviceTypeName')}
          </ResourceProperty>
          <ResourceProperty label="Estado:">
            <IsActive value={itinerary.get('active')} />
          </ResourceProperty>
          <ResourceProperty label="Tipo:">
            {ITINERARY_TYPE[itinerary.get('type').toLowerCase()].label}
          </ResourceProperty>
          <ResourceProperty label="Observaciones Adicionales:">
            {itinerary.get('comment')}
          </ResourceProperty>
        </Fragment>
      );

      const busResource = (
        <Fragment>
          <ResourceProperty label="Número:">
            {itinerary.get('companyBusId')
              ? itinerary.get('companyBusId')
              : '-'}
          </ResourceProperty>
          <ResourceProperty label="Matrícula:">
            {itinerary.get('licensePlate')
              ? itinerary.get('licensePlate')
              : '-'}
          </ResourceProperty>
          <ResourceProperty label="Estado:">
            {itinerary.get('tripStatus')
              ? itinerary.get('tripStatus').name
              : '-'}
          </ResourceProperty>
        </Fragment>
      );

      let timetableTemplate = (
        <Alert message={ITINERARY_SCHEDULE_WITHOUT_SEGMENT_LIST_MESSAGE} />
      );

      if (itinerary.get('itineraryScheduleSegmentList').length) {
        timetableTemplate = (
          <TimetableTemplate
            itineraryScheduleSegmentList={itinerary.get(
              'itineraryScheduleSegmentList',
            )}
            estimatedDepartureTime={itinerary.get('estimatedDepartureTime')}
          />
        );
      }

      const driverData = itinerary.get('driverList');

      let driverResource = (
        <Alert message={ITINERARY_WITHOUT_DRIVER_INFO_MESSAGE} />
      );

      if (driverData && driverData.length > 0) {
        driverResource = (
          <div className="mb-3">
            <Table
              columns={[
                {
                  Header: 'Nombre',
                  accessor: 'fullName',
                  className: 'text-center',
                },
                {
                  Header: 'DNI',
                  accessor: 'idDocumentNumber',
                  className: 'text-center',
                },
              ]}
              data={driverData}
              defaultPageSize={driverData.length}
              showPagination={false}
            />
          </div>
        );
      }

      const cabinCrewData = itinerary.get('cabinCrewList');

      let cabinCrewResource = (
        <Alert
          type="warning"
          message={ITINERARY_WITHOUT_CABIN_CREW_INFO_MESSAGE}
        />
      );

      if (cabinCrewData.length > 0) {
        cabinCrewResource = (
          <div className="mb-3">
            <Table
              columns={[
                {
                  Header: 'Nombre',
                  accessor: 'fullName',
                  className: 'text-center',
                },
                {
                  Header: 'DNI',
                  accessor: 'idDocumentNumber',
                  className: 'text-center',
                },
              ]}
              data={cabinCrewData}
              defaultPageSize={cabinCrewData.length}
              showPagination={false}
            />
          </div>
        );
      }

      let cargoItemResource = (
        <Alert type="warning" message={ITINERARY_WITHOUT_CARGO_MESSAGE} />
      );

      const data = cargoItems.get('content') || [];
      const pages = cargoItems.get('totalPages') || null;
      const defaultPageSize = cargoItems.get('size') || DEFAULT_PAGE_SIZE;
      if (data.length > 0) {
        cargoItemResource = (
          <div className="mb-3">
            <Table
              columns={CARGO_ITINERARY_COLUMNS}
              data={data}
              pages={pages}
              defaultPageSize={defaultPageSize}
              loading={loadingCargoItems}
              fetchData={dispatchGetCargoItemByItinerary}
              params={{ itineraryId }}
            />
          </div>
        );
      }

      const stadisticResource = (
        <Fragment>
          <ResourceProperty label="Hora Estimada de Salida:">
            {itinerary.get('estimatedDepartureTime')
              ? tzNormalizeDate({
                  date: itinerary.get('estimatedDepartureTime'),
                  format: DATE_TIME_FORMAT,
                })
              : '-'}
          </ResourceProperty>
          <ResourceProperty label="Hora Real de Salida:">
            {itinerary.get('actualDepartureTime')
              ? tzNormalizeDate({
                  date: itinerary.get('actualDepartureTime'),
                  format: DATE_TIME_FORMAT,
                })
              : '-'}
          </ResourceProperty>
          <ResourceProperty label="Hora Estimada de Llegada:">
            {itinerary.get('estimatedArrivalTime')
              ? tzNormalizeDate({
                  date: itinerary.get('estimatedArrivalTime'),
                  format: DATE_TIME_FORMAT,
                })
              : '-'}
          </ResourceProperty>
          <ResourceProperty label="Hora Real de Llegada:">
            {itinerary.get('actualArrivalTime')
              ? tzNormalizeDate({
                  date: itinerary.get('actualArrivalTime'),
                  format: DATE_TIME_FORMAT,
                })
              : '-'}
          </ResourceProperty>
        </Fragment>
      );

      let passengerFlowTable = (
        <Alert
          type="warning"
          message={ITINERARY_WITHOUT_PASSENGER_FLOW_MESSAGE}
        />
      );

      if (!passengerFlow.isEmpty()) {
        const passengerFlowData = passengerFlow.get(
          'passengerFlowForLocationList',
        );

        passengerFlowTable = (
          <div className="mb-3">
            <Table
              columns={[
                {
                  Header: 'Parada Autorizada',
                  accessor: 'locationName',
                  className: 'text-center',
                },
                {
                  Header: 'Embarques',
                  accessor: 'boardingPassengers',
                  className: 'text-center',
                  Cell: (row) =>
                    this.renderPassengersFlow('boarding', row.original),
                },
                {
                  Header: 'Desembarques',
                  accessor: 'leavingPassengers',
                  className: 'text-center',
                  Cell: (row) =>
                    this.renderPassengersFlow('leaving', row.original),
                },
              ]}
              data={passengerFlowData}
              defaultPageSize={passengerFlowData.length}
              showPagination={false}
            />
          </div>
        );
      }

      const passengerFlowResource = (
        <Fragment>
          <h3>Flujo de Pasajeros</h3>
          {passengerFlowTable}
        </Fragment>
      );

      content = (
        <Fragment>
          {itineraryResource}
          <h3>Horarios</h3>
          <div className="mb-3">{timetableTemplate}</div>
          <h3>Bus</h3>
          {busResource}
          <h3>Conductores</h3>
          {driverResource}
          {passengerFlowResource}
          <h3>Servicio a Bordo</h3>
          {cabinCrewResource}
          <h3>Carga asignada</h3>
          {cargoItemResource}
          <h3>Estadísticas</h3>
          {stadisticResource}
          <Modal
            show={showModal}
            title={modalTitle}
            body={modalBody}
            onClickClose={() => this.setState({ showModal: false })}
          />
        </Fragment>
      );

      if (itinerary.get('companyBusId') !== null) {
        toolbar = (
          <ItineraryForCounterToolbar
            itineraryId={itinerary.get('id')}
            drivers={drivers}
          />
        );
      }
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Itinerario"
        toolbar={toolbar}
        content={content}
      />
    );
  }
}

const mapStateToProps = ({ ItineraryUnit, CargoUnit }) => ({
  breadcrumbs: [
    ...ItineraryUnit.UnitHome.get('breadcrumbs'),
    { text: 'Búsqueda de Itinerarios', href: ITINERARY_FOR_COUNTER_PATH },
    { text: 'Ver', href: '' },
  ],
  itinerary: ItineraryUnit.Itinerary.getIn(['current', 'content']),
  loading: !ItineraryUnit.Itinerary.getIn(['current', 'activity']).isEmpty(),
  drivers: ItineraryUnit.Itinerary.getIn(['current', 'drivers']),
  cargoItems: CargoUnit.ItemToItinerary.getIn(['current', 'items']),
  loadingCargoItems: !CargoUnit.ItemToItinerary.getIn([
    'current',
    'activity',
  ]).isEmpty(),
  passengerFlow: ItineraryUnit.Itinerary.getIn(['current', 'passengerFlow']),
});

const mapDispatchToProps = {
  dispatchGetItineraryForCounter: getItineraryForCounter,
  dispatchClearItinerary: clearItinerary,
  dispatchGetListDriversForItinerary: getListDriversForItinerary,
  dispatchClearDrivers: clearDrivers,
  dispatchGetCargoItemByItinerary: getCargoItemByItinerary,
  dispatchClearItemToItinerary: clearItemToItinerary,
  dispatchGetPassengerFlowForItinerary: getPassengerFlowForItinerary,
  dispatchClearPassengerFlowForItinerary: clearPassengerFlowForItinerary,
};

Itinerary.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  match: matchPropTypes.isRequired,
  itinerary: PropTypes.instanceOf(Immutable.Map).isRequired,
  drivers: PropTypes.instanceOf(Immutable.Set).isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchGetItineraryForCounter: PropTypes.func.isRequired,
  dispatchClearItinerary: PropTypes.func.isRequired,
  dispatchGetListDriversForItinerary: PropTypes.func.isRequired,
  dispatchClearDrivers: PropTypes.func.isRequired,
  dispatchGetCargoItemByItinerary: PropTypes.func.isRequired,
  dispatchClearItemToItinerary: PropTypes.func.isRequired,
  cargoItems: PropTypes.instanceOf(Immutable.Set).isRequired,
  loadingCargoItems: PropTypes.bool.isRequired,
  dispatchGetPassengerFlowForItinerary: PropTypes.func.isRequired,
  dispatchClearPassengerFlowForItinerary: PropTypes.func.isRequired,
  passengerFlow: PropTypes.instanceOf(Immutable.Map).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Itinerary);
