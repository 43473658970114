import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import Breadcrumbs from '../../../layout/Breadcrumbs';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import ItemToItineraryForm from './ItemToItineraryForm';
import { tzNormalizeDate } from '../../../../utils/date';

// eslint-disable-next-line react/prefer-stateless-function
export class ItemToItinerary extends Component {
  render() {
    const { breadcrumbs } = this.props;
    return (
      <Container fluid>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <h1>Agregar Item a Itinerario</h1>
        <p>Agregar Item a Itinerario</p>
        <ItemToItineraryForm
          initialValues={{
            createDate: tzNormalizeDate(),
          }}
        />
      </Container>
    );
  }
}

ItemToItinerary.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Agregar Item a Itinerario',
      href: '',
    },
  ],
});

export default connect(mapStateToProps)(ItemToItinerary);
