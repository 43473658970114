import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  CLEAR_CARGO_PRICE,
  FLAG_PARCEL_ACTIVITY,
  POST_CARGO_PRICE,
  CLEAR_CARGO_PRICES,
  FLAG_GETTING_PARCELS,
  GET_PARCELS,
  CLEAR_PARCELS,
  GET_PARCEL,
  CLEAR_PARCEL,
} from '../types/cargo';
import {
  CARGO_PRICE_ENDPOINT,
  PARCEL_ENDPOINT,
  generatePostParcelRefund,
  generateSalesSessionParcelsEnpoint,
} from '../../config/endpoints';
import { DEFAULT_POST_CONFIG, DEFAULT_GET_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { CARGO_PATH } from '../../config/paths';

const clearCargoPrices = () => (dispatch) =>
  dispatch({
    type: CLEAR_CARGO_PRICES,
  });

const clearCargoPrice = (index) => (dispatch) =>
  dispatch({
    type: CLEAR_CARGO_PRICE,
    payload: index,
  });

const flagParcelActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_PARCEL_ACTIVITY,
    payload: flag,
  });

const flagGettingParcels = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_PARCELS,
    payload: flag,
  });

const clearParcels = () => (dispatch) =>
  dispatch({
    type: CLEAR_PARCELS,
  });

const getParcels = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingParcels(true));
    const query = tableFilters;
    const url = `${PARCEL_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response);
    const parcels = await response.json();
    dispatch({
      type: GET_PARCELS,
      payload: parcels,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingParcels(false));
  }
};

const getSalesSessionParcels =
  async ({ salesSessionId }, tableFilters) =>
  async (dispatch) => {
    try {
      dispatch(flagGettingParcels(true));
      const query = {
        ...tableFilters,
        query: [...tableFilters.query, `salesSessionId:${salesSessionId}`],
      };
      const url = `${generateSalesSessionParcelsEnpoint(
        salesSessionId,
      )}?${QueryString.stringify(query)}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response);
      const parcels = await response.json();
      dispatch({
        type: GET_PARCELS,
        payload: parcels,
      });
    } catch ({ message }) {
      toastr.error('Error', message);
    } finally {
      dispatch(flagGettingParcels(false));
    }
  };

const postCargoPrice =
  async (indexes, { sourceCityId, destinationCityId, cargoItemList }) =>
  async (dispatch) => {
    try {
      dispatch(flagParcelActivity(true));
      const payload = {
        sourceCityId,
        destinationCityId,
        cargoItemList,
      };
      const url = CARGO_PRICE_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response);
      const prices = await response.json();
      const payloadForDispatch = prices.map((price, index) => ({
        index: indexes[index],
        price,
      }));
      dispatch({
        type: POST_CARGO_PRICE,
        payload: payloadForDispatch,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagParcelActivity(false));
    }
  };

const postParcel =
  async ({
    voucherTypeId,
    voucherCode,
    sourceCityId,
    sourceLocationId,
    destinationCityId,
    destinationLocationId,
    senderCustomerId,
    consigneeCustomerId,
    paymentMethodId,
    comments,
    cargoItemList,
    listPrice,
    salePrice,
    cargoDelivery,
    customerId,
    businessId,
    secretCode,
    attachedDocument,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagParcelActivity(true));
      const payload = {
        voucherTypeId,
        voucherCode,
        sourceCityId,
        sourceLocationId,
        destinationCityId,
        destinationLocationId,
        senderCustomerId,
        consigneeCustomerId,
        paymentMethodId,
        comments,
        cargoItemList,
        listPrice,
        salePrice,
        cargoDelivery,
        customerId,
        businessId,
        secretCode,
        attachedDocument,
      };
      const url = PARCEL_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response);
      const parcel = await response.json();
      dispatch(push(`${CARGO_PATH}/${parcel.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagParcelActivity(false));
    }
  };

const getParcel =
  async ({ parcelId }) =>
  async (dispatch) => {
    try {
      dispatch(flagParcelActivity(true));
      const url = `${PARCEL_ENDPOINT}/${parcelId}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response);
      const parcel = await response.json();
      dispatch({
        type: GET_PARCEL,
        payload: parcel,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagParcelActivity(false));
    }
  };

const clearParcel = () => (dispatch) =>
  dispatch({
    type: CLEAR_PARCEL,
  });

const postParcelRefund =
  async ({ parcelId }) =>
  async (dispatch) => {
    try {
      dispatch(flagParcelActivity(true));
      const url = generatePostParcelRefund(parcelId);
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
      });

      await isErrorResponse(response, () => getParcel({ parcelId }), dispatch);
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagParcelActivity(false));
    }
  };

export {
  clearCargoPrices,
  clearCargoPrice,
  postCargoPrice,
  postParcel,
  getParcels,
  getSalesSessionParcels,
  clearParcels,
  flagParcelActivity,
  getParcel,
  clearParcel,
  postParcelRefund,
};
