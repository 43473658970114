import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { PRICING_PROFILE_PATH } from '../../../../config/paths';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import PricingProfileForm from './PricingProfileForm';
import Loader from '../../../common/Loader';
import {
  clearPricingProfile,
  getPricingProfile,
  putPricingProfile,
} from '../../../../actions';
import { tzNormalizeDate } from '../../../../utils/date';
import NoDataResource from '../../../common/resource/NoDataResource';
import { TIMESTAMP_FORMAT } from '../../../../config/locale';
import Content from '../../../layout/Content';

const EditPricingProfile = ({
  breadcrumbs,
  pricingProfile,
  loading,
  match: {
    params: { id },
  },
  dispatchPutPricingProfile,
  dispatchClearPricingProfile,
  dispatchGetPricingProfile,
}) => {
  const [initialPriceDates, setInitialPriceDates] = useState(null);
  const [priceDaysId, setPriceDaysId] = useState(null);
  const [numberOfDatesShowed] = useState(15);

  useLayoutEffect(() => {
    dispatchGetPricingProfile({ pricingProfileId: id });

    return () => dispatchClearPricingProfile();
  }, []);

  const onSubmit = (formValues) => {
    const newFormValues = {
      agencyGroups: [],
      name: formValues.name,
      basePrice: formValues.basePrice,
      isSeatMapPricing: false,
      itineraryLevelPricing: true,
      isMaciva: formValues.isMaciva || false,
      priceDays: null,
      priceDatesList: [],
      priceZoneList: [],
      seatMapId: null,
    };

    if (!formValues.isMaciva) {
      if (formValues.agencyGroups)
        newFormValues.agencyGroups = formValues.agencyGroups.map(
          ({ value }) => ({
            id: value,
          }),
        );

      if (formValues.isPriceByDay) {
        newFormValues.priceDays = {
          monday: formValues.monday || null,
          tuesday: formValues.tuesday || null,
          wednesday: formValues.wednesday || null,
          thursday: formValues.thursday || null,
          friday: formValues.friday || null,
          saturday: formValues.saturday || null,
          sunday: formValues.sunday || null,
        };

        if (priceDaysId) {
          newFormValues.priceDays.id = priceDaysId;
        }
      }

      if (initialPriceDates)
        newFormValues.priceDatesList = [...initialPriceDates];

      if (formValues.priceDatesList && formValues.priceDatesList.length) {
        formValues.priceDatesList.forEach((date) => {
          newFormValues.priceDatesList.push({
            absoluteChange: date.absoluteChange,
            endDate: tzNormalizeDate({
              date: date.endDate,
              format: TIMESTAMP_FORMAT,
              time: 'start',
            }),
            id: date.id,
            percentChange: 0,
            pricingProfileId: id,
            startDate: tzNormalizeDate({
              date: date.startDate,
              format: TIMESTAMP_FORMAT,
              time: 'start',
            }),
          });
        });
      }

      if (formValues.priceZoneList) {
        newFormValues.seatMapId = formValues.seatMapId.value;
        newFormValues.priceZoneList = formValues.priceZoneList.map((zone) => ({
          basePriceOverride: zone.basePriceOverride,
          endXPosition: zone.endXPosition,
          endYPosition: zone.endYPosition,
          floorNumber: zone.itemfloorNumber.value,
          startXPosition: zone.startXPosition,
          startYPosition: zone.startYPosition,
        }));
      }
    }

    newFormValues.seatPriceOverrideList = [];
    newFormValues.lastUpdate = Date.now();
    newFormValues.version = pricingProfile.get('version');

    dispatchPutPricingProfile(id, newFormValues);
  };

  const generateInitialValues = ({
    name,
    basePrice,
    isMaciva,
    priceDays,
    agencyGroups,
    priceDatesList,
    seatMap,
    seatMapDTO,
    priceZoneList,
  }) => {
    const initialValues = {
      name,
      basePrice,
      isMaciva,
      isPriceByDay: !!priceDays,
      agencyGroups: agencyGroups.map((agencyGroup) => ({
        value: agencyGroup.id,
        label: agencyGroup.name,
      })),
    };

    if (priceDays) {
      const {
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
        id: currentPriceDaysId,
      } = priceDays;

      initialValues.monday = monday;
      initialValues.tuesday = tuesday;
      initialValues.wednesday = wednesday;
      initialValues.thursday = thursday;
      initialValues.friday = friday;
      initialValues.saturday = saturday;
      initialValues.sunday = sunday;

      if (!priceDaysId) setPriceDaysId(currentPriceDaysId);
    }

    if (priceDatesList) {
      let newPriceDatesList = priceDatesList.map((priceDates) => priceDates);

      const lengthPriceDatesList = newPriceDatesList.length;

      let newInitialPriceDates = [];

      if (lengthPriceDatesList > numberOfDatesShowed) {
        newInitialPriceDates = newPriceDatesList.slice(
          0,
          lengthPriceDatesList - numberOfDatesShowed,
        );

        if (!initialPriceDates) setInitialPriceDates(newInitialPriceDates);

        newPriceDatesList = newPriceDatesList.slice(
          lengthPriceDatesList - numberOfDatesShowed,
          lengthPriceDatesList,
        );
      }

      initialValues.initialPriceDates = newInitialPriceDates;

      initialValues.priceDatesList = newPriceDatesList;
    }

    if (seatMap) {
      initialValues.seatMapId = {
        value: seatMap.id,
        label: seatMap.name,
        floors: seatMapDTO.floors,
      };

      initialValues.priceZoneList = priceZoneList.map(
        ({
          floorNumber,
          basePriceOverride,
          startXPosition,
          startYPosition,
          endXPosition,
          endYPosition,
        }) => ({
          itemfloorNumber: { value: floorNumber, label: floorNumber },
          basePriceOverride,
          startXPosition,
          startYPosition,
          endXPosition,
          endYPosition,
        }),
      );
    }

    return initialValues;
  };

  let content = null;

  if (loading) content = <Loader />;
  else if (pricingProfile.isEmpty())
    content = <NoDataResource returnPage={PRICING_PROFILE_PATH} />;
  else
    content = (
      <PricingProfileForm
        onSubmit={onSubmit}
        initialValues={generateInitialValues(pricingProfile.toJS())}
      />
    );

  return (
    <Content
      breadcrumbs={breadcrumbs}
      title="Editar Perfil de Precio"
      subtitle="Editar perfil de precio"
      content={content}
    />
  );
};

const mapStateToProps = (
  { SalesUnit },
  {
    match: {
      params: { id },
    },
  },
) => ({
  breadcrumbs: [
    ...SalesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Perfiles de Precios',
      href: PRICING_PROFILE_PATH,
    },
    {
      text: 'Ver',
      href: `${PRICING_PROFILE_PATH}/${id}`,
    },
    {
      text: 'Editar',
      href: '',
    },
  ],
  pricingProfile: SalesUnit.PricingProfile.getIn(['current', 'content']),
  loading: !SalesUnit.PricingProfile.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetPricingProfile: getPricingProfile,
  dispatchClearPricingProfile: clearPricingProfile,
  dispatchPutPricingProfile: putPricingProfile,
};

EditPricingProfile.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPutPricingProfile: PropTypes.func.isRequired,
  dispatchGetPricingProfile: PropTypes.func.isRequired,
  dispatchClearPricingProfile: PropTypes.func.isRequired,
  match: matchPropTypes.isRequired,
  pricingProfile: PropTypes.instanceOf(Immutable.Map).isRequired,
  loading: PropTypes.bool,
};

EditPricingProfile.defaultProps = {
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPricingProfile);
