import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import {
  AGENCY_GROUP_PATH,
  PRICING_PROFILE_PATH,
} from '../../../../config/paths';
import { getPricingProfile, clearPricingProfile } from '../../../../actions';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import Loader from '../../../common/Loader';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import { DATE_FORMAT } from '../../../../config/locale';
import PricingProfileToolbar from './PricingProfileToolbar';
import Alert from '../../../common/informative/Alert';
import { PRICE_BY_DAY_OF_WEEK_ARE_ZERO_WARNING_MESSAGE } from '../../../../config/messages';
import NoDataResource from '../../../common/resource/NoDataResource';
import Content from '../../../layout/Content';
import InternalResource from '../../../common/resource/InternalResource';
import { numberFormatter } from '../../../../utils/number';
import { orderBy } from '../../../../utils/array';
import BadgeList from '../../../common/BadgeList';
import { tzNormalizeDate } from '../../../../utils/date';
import Checkbox from '../../../common/forms/Checkbox';

class PricingProfile extends Component {
  componentDidMount() {
    const {
      dispatchGetPricingProfile,
      match: {
        params: { id: pricingProfileId },
      },
    } = this.props;

    dispatchGetPricingProfile({ pricingProfileId });
  }

  componentWillUnmount() {
    const { dispatchClearPricingProfile } = this.props;
    dispatchClearPricingProfile();
  }

  render() {
    const { breadcrumbs, pricingProfile, loading } = this.props;

    let content = null;

    let toolbar = null;

    if (loading) {
      content = <Loader />;
    } else if (pricingProfile.isEmpty()) {
      content = <NoDataResource returnPage={PRICING_PROFILE_PATH} />;
    } else {
      const basePrice = pricingProfile.get('basePrice');

      let priceDaysResource = null;

      let priceDatesResource = null;

      let priceByZonesResource = null;

      let agencyGroupsResource = null;

      let checkboxPriceDaysResource = (
        <p>
          <Checkbox checked={pricingProfile.get('priceDays')} /> Cambios de
          precio por día de semana
        </p>
      );

      if (pricingProfile.get('isMaciva')) {
        checkboxPriceDaysResource = null;
      } else {
        if (pricingProfile.get('priceDays')) {
          const {
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
            sunday,
          } = pricingProfile.get('priceDays');

          let priceByDayOfWeekResource = (
            <Row>
              <Col>
                <ResourceProperty label="Lunes:">
                  {monday ? numberFormatter({ value: monday }) : '-'}
                </ResourceProperty>
              </Col>
              <Col>
                <ResourceProperty label="Martes:">
                  {tuesday ? numberFormatter({ value: tuesday }) : '-'}
                </ResourceProperty>
              </Col>
              <Col>
                <ResourceProperty label="Miércoles:">
                  {wednesday ? numberFormatter({ value: wednesday }) : '-'}
                </ResourceProperty>
              </Col>
              <Col>
                <ResourceProperty label="Jueves:">
                  {thursday ? numberFormatter({ value: thursday }) : '-'}
                </ResourceProperty>
              </Col>
              <Col>
                <ResourceProperty label="Viernes:">
                  {friday ? numberFormatter({ value: friday }) : '-'}
                </ResourceProperty>
              </Col>
              <Col>
                <ResourceProperty label="Sábado:">
                  {saturday ? numberFormatter({ value: saturday }) : '-'}
                </ResourceProperty>
              </Col>
              <Col>
                <ResourceProperty label="Domingo:">
                  {sunday ? numberFormatter({ value: sunday }) : '-'}
                </ResourceProperty>
              </Col>
            </Row>
          );

          if (
            monday === 0 &&
            tuesday === 0 &&
            wednesday === 0 &&
            thursday === 0 &&
            friday === 0 &&
            saturday === 0 &&
            sunday === 0
          ) {
            priceByDayOfWeekResource = (
              <Alert
                message={PRICE_BY_DAY_OF_WEEK_ARE_ZERO_WARNING_MESSAGE}
                type="warning"
              />
            );
          }

          priceDaysResource = (
            <Fragment>
              <h3>Cambios de precio por día de semana</h3>
              <p>
                Cambio porcentual del precio base en un día determinado de la
                semana
              </p>
              {priceByDayOfWeekResource}
            </Fragment>
          );
        }

        if (pricingProfile.get('priceDatesList').length) {
          const priceDatesData = pricingProfile.get('priceDatesList');

          let otherColumns = [
            {
              Header: 'Precio Base',
              accessor: 'absoluteChange',
              className: 'text-center',
              Cell: ({ value }) =>
                numberFormatter({ value: value + basePrice }),
            },
          ];

          if (pricingProfile.get('priceZoneList')) {
            otherColumns = pricingProfile
              .get('priceZoneList')
              .map((price, index) => ({
                Header: `Zona${(index + 1).toString()}Piso${price.floorNumber}`,
                accessor: `basePriceOverride${(index + 1).toString()}${
                  price.floorNumber
                }`,
                className: 'text-center',
                Cell: ({ value, original }) =>
                  numberFormatter({ value: value + original.absoluteChange }),
              }));

            priceDatesData.forEach((date, dateIndex) => {
              pricingProfile
                .get('priceZoneList')
                .forEach((price, priceIndex) => {
                  priceDatesData[dateIndex][
                    `basePriceOverride${(priceIndex + 1).toString()}${
                      price.floorNumber
                    }`
                  ] = price.basePriceOverride;
                });
            });
          }

          const priceDatesColumns = [
            {
              Header: 'Fecha Inicial',
              accessor: 'startDate',
              className: 'text-center',
              Cell: ({ value }) =>
                tzNormalizeDate({ date: value, format: DATE_FORMAT }),
            },
            {
              Header: 'Fecha Final',
              accessor: 'endDate',
              className: 'text-center',
              Cell: ({ value }) =>
                tzNormalizeDate({ date: value, format: DATE_FORMAT }),
            },
            {
              Header: 'Diferencia',
              accessor: 'absoluteChange',
              className: 'text-center',
              Cell: ({ value }) => numberFormatter({ value }),
            },
            ...otherColumns,
          ];

          const sortedPriceDatesData = orderBy(
            priceDatesData,
            ['id'],
            ['desc'],
          );

          const priceDatesTable = (
            <Fragment>
              <ReactTable
                columns={priceDatesColumns}
                data={sortedPriceDatesData}
                defaultPageSize={5}
              />
            </Fragment>
          );

          priceDatesResource = (
            <Fragment>
              <h3>Variación de precio por rango de fechas</h3>
              <p>
                Especifique los rangos de fechas para los que desea aplicar una
                variación de precio
              </p>
              {priceDatesTable}
              <br />
            </Fragment>
          );
        }

        if (pricingProfile.get('priceZoneList').length) {
          priceByZonesResource = (
            <Fragment>
              <h3>Variación de precio por zonas</h3>
              <p>
                Seleccione un mapa de asientos para el que desea aplicar una
                variación de precio.
              </p>
              <Row>
                {pricingProfile.get('priceZoneList').map((priceZone, index) => (
                  <Col key={`col${index.toString()}`}>
                    <h5>Zona {index + 1}</h5>
                    <ResourceProperty label="Piso:">
                      {priceZone.floorNumber}
                    </ResourceProperty>
                    <ResourceProperty label="Precio:">
                      {numberFormatter({ value: priceZone.basePriceOverride })}
                    </ResourceProperty>
                    <h5>Arriba Izquierda</h5>
                    <ResourceProperty label="Columna:">
                      {priceZone.startXPosition}
                    </ResourceProperty>
                    <ResourceProperty label="Fila:">
                      {priceZone.startYPosition}
                    </ResourceProperty>
                    <h5>Abajo Derecha</h5>
                    <ResourceProperty label="Columna:">
                      {priceZone.endXPosition}
                    </ResourceProperty>
                    <ResourceProperty label="Fila:">
                      {priceZone.endYPosition}
                    </ResourceProperty>
                  </Col>
                ))}
              </Row>
            </Fragment>
          );
        }

        agencyGroupsResource = (
          <ResourceProperty label="Grupos de Agencias:">
            <BadgeList
              textArray={pricingProfile
                .get('agencyGroups')
                .map(({ name, id }) => ({
                  text: name,
                  href: `${AGENCY_GROUP_PATH}/${id}`,
                }))}
            />
          </ResourceProperty>
        );
      }

      const internalDataResource = (
        <InternalResource
          id={pricingProfile.get('id')}
          createDate={pricingProfile.get('createDate')}
          lastUpdate={pricingProfile.get('lastUpdate')}
        />
      );

      content = (
        <Fragment>
          <ResourceProperty label="Nombre:">
            {pricingProfile.get('name')}
          </ResourceProperty>
          <ResourceProperty label="Precio Base:">
            {numberFormatter({ value: basePrice })}
          </ResourceProperty>
          <p>
            <Checkbox checked={pricingProfile.get('isMaciva')} /> Maciva
          </p>
          {checkboxPriceDaysResource}
          {priceDaysResource}
          {priceDatesResource}
          {priceByZonesResource}
          {agencyGroupsResource}
          {internalDataResource}
        </Fragment>
      );

      toolbar = (
        <PricingProfileToolbar pricingProfileId={pricingProfile.get('id')} />
      );
    }

    return (
      <Content
        breadcrumbs={breadcrumbs}
        toolbar={toolbar}
        title="Perfil de Precios"
        content={content}
      />
    );
  }
}

PricingProfile.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetPricingProfile: PropTypes.func.isRequired,
  dispatchClearPricingProfile: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  pricingProfile: PropTypes.instanceOf(Immutable.Map).isRequired,
};

PricingProfile.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ SalesUnit }) => ({
  breadcrumbs: [
    ...SalesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Perfiles de Precios',
      href: PRICING_PROFILE_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  pricingProfile: SalesUnit.PricingProfile.getIn(['current', 'content']),
  loading: !SalesUnit.PricingProfile.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetPricingProfile: getPricingProfile,
  dispatchClearPricingProfile: clearPricingProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(PricingProfile);
