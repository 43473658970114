import React from 'react';
import { connect } from 'react-redux';
import { Field, change, reduxForm } from 'redux-form';
import { string, number, PropTypes } from 'prop-types';
import { Button, FormGroup, Form } from 'reactstrap';
import {
  isRequired,
  validateIntegerNumber,
  validateLength,
} from '../../../../utils/validators';
import FormItem from '../../../common/forms/FormItem';
import TextInput from '../../../common/forms/input/TextInput';
import FormFooter from '../../../common/forms/FormFooter';
import ResourceProperty from '../../../common/resource/ResourceProperty';
import PaymentMethodInputGroup from '../../../common/forms/PaymentMethodInputGroup';
import { PAYMENT_METHOD_PAGO_EN_DESTINO } from '../../../../config/constants';

const validateLength4 = validateLength(4, 'dígitos');

const CargoPickupModel = ({
  handleSubmit,
  parcel,
  handleProcess,
  onCancel,
  dispatchChange,
}) => {
  const onShowVoucherCode = () => {
    dispatchChange('CargoPickupModelForm', 'voucherCode', '');
  };

  const paymenthMethodInput =
    parcel.transactionCode === PAYMENT_METHOD_PAGO_EN_DESTINO ? (
      <PaymentMethodInputGroup onShowVoucherCode={onShowVoucherCode} />
    ) : null;

  return (
    <Form onSubmit={handleSubmit(handleProcess)}>
      <ResourceProperty label="Serie:">
        {parcel.documentSeries}
      </ResourceProperty>
      <ResourceProperty label="Número de serie:">
        {parcel.documentCode}
      </ResourceProperty>
      <ResourceProperty label="Origen:">
        {parcel.sourceCityName}
      </ResourceProperty>
      <ResourceProperty label="Destino:">
        {parcel.destinationCityName}
      </ResourceProperty>
      <hr />
      {paymenthMethodInput}
      <FormGroup row>
        <FormItem label="Clave" required>
          <Field
            name="secretCode"
            component={TextInput}
            type="text"
            placeholder="Clave secreta"
            validate={[isRequired, validateIntegerNumber, validateLength4]}
          />
        </FormItem>
      </FormGroup>
      <FormFooter
        saveButtonColor="secondary"
        saveButtonIcon="fa fa-share"
        saveButtonText="Entregar"
      >
        <Button color="primary" onClick={onCancel}>
          Cancelar
        </Button>
      </FormFooter>
    </Form>
  );
};

const parcelPropTypes = {
  documentSeries: string,
  documentCode: number,
  sourceCityName: string,
  destinationCityName: string,
  paymentMethodId: number,
};

CargoPickupModel.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  parcel: PropTypes.shape(parcelPropTypes).isRequired,
  handleProcess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  dispatchChange: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchChange: change,
};

const formComponent = reduxForm({
  form: 'CargoPickupModelForm',
})(CargoPickupModel);

export default connect(null, mapDispatchToProps)(formComponent);
