import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PRICING_PROFILE_PATH } from '../../../../config/paths';
import { postPricingProfile } from '../../../../actions';
import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import PricingProfileForm from './PricingProfileForm';
import Loader from '../../../common/Loader';
import { TIMESTAMP_FORMAT } from '../../../../config/locale';
import Content from '../../../layout/Content';
import { tzNormalizeDate } from '../../../../utils/date';

export class NewPricingProfile extends Component {
  onSubmit = (formValues) => {
    const newFormValues = {
      name: formValues.name,
      version: null,
      basePrice: formValues.basePrice,
      isSeatMapPricing: false,
      itineraryLevelPricing: true,
      isMaciva: formValues.isMaciva || false,
      priceDays: null,
      priceDatesList: [],
      seatMapId: null,
      agencyGroups: [],
    };

    if (!formValues.isMaciva) {
      if (formValues.agencyGroups) {
        newFormValues.agencyGroups = formValues.agencyGroups.map(
          ({ value }) => ({
            id: value,
          }),
        );
      }

      if (formValues.isPriceByDay) {
        newFormValues.priceDays = {
          monday: formValues.monday || null,
          tuesday: formValues.tuesday || null,
          wednesday: formValues.wednesday || null,
          thursday: formValues.thursday || null,
          friday: formValues.friday || null,
          saturday: formValues.saturday || null,
          sunday: formValues.sunday || null,
        };
      }

      if (formValues.priceDatesList) {
        newFormValues.priceDatesList = formValues.priceDatesList.map(
          (date) => ({
            absoluteChange: date.absoluteChange,
            endDate: tzNormalizeDate({
              date: date.endDate,
              format: TIMESTAMP_FORMAT,
            }),
            id: '',
            percentChange: 0,
            pricingProfileId: null,
            startDate: tzNormalizeDate({
              date: date.startDate,
              format: TIMESTAMP_FORMAT,
            }),
          }),
        );
      }

      if (formValues.priceZoneList) {
        newFormValues.seatMapId = formValues.seatMapId.value;
        newFormValues.priceZoneList = formValues.priceZoneList.map((zone) => ({
          basePriceOverride: zone.basePriceOverride,
          endXPosition: zone.endXPosition,
          endYPosition: zone.endYPosition,
          floorNumber: zone.itemfloorNumber.value,
          startXPosition: zone.startXPosition,
          startYPosition: zone.startYPosition,
        }));
      }
    }

    const { dispatchPostPricingProfile } = this.props;

    dispatchPostPricingProfile(newFormValues);
  };

  render() {
    const { loading, breadcrumbs } = this.props;

    const form = loading ? (
      <Loader />
    ) : (
      <PricingProfileForm onSubmit={this.onSubmit} />
    );

    return (
      <Content
        breadcrumbs={breadcrumbs}
        title="Nuevo Perfil de Precio"
        subtitle="Crear un nuevo perfil de precio"
        content={form}
      />
    );
  }
}

const mapStateToProps = ({ SalesUnit }) => ({
  breadcrumbs: [
    ...SalesUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Perfiles de Precios',
      href: PRICING_PROFILE_PATH,
    },
    {
      text: 'Nuevo',
      href: '',
    },
  ],
  loading: !SalesUnit.PricingProfile.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchPostPricingProfile: postPricingProfile,
};

NewPricingProfile.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchPostPricingProfile: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

NewPricingProfile.defaultProps = {
  loading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPricingProfile);
