import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import RefundButton from './RefundButton';
import PrintCargoTicketButton from '../../reservation/ticket/ticket-toolbar/PrintCargoTicketButton';
import PrintCargoVoucherButton from '../../reservation/ticket/ticket-toolbar/PrintCargoVoucherButton';

const ParcelToolbar = ({ parcel }) => (
  <ButtonToolbar className="pull-right">
    <div>
      <PrintCargoTicketButton ticket={parcel} />{' '}
      <PrintCargoVoucherButton voucher={parcel} />{' '}
      <RefundButton parcelId={parcel.id} status={parcel.status} />
    </div>
  </ButtonToolbar>
);

ParcelToolbar.propTypes = {
  parcel: PropTypes.instanceOf(Object).isRequired,
};

export default ParcelToolbar;
