import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import {
  FLAG_GETTING_INTERNAL_PARCELS,
  GET_INTERNAL_PARCELS,
  CLEAR_INTERNAL_PARCEL,
  FLAG_INTERNAL_PARCEL_ACTIVITY,
  GET_INTERNAL_PARCEL,
  CLEAR_INTERNAL_PARCELS,
} from '../types/cargo';
import { INTERNAL_PARCEL_ENDPOINT } from '../../config/endpoints';
import { DEFAULT_POST_CONFIG, DEFAULT_GET_CONFIG } from '../../config/rest';
import { isErrorResponse } from '../../utils/error-handlers';
import { INTERNAL_TRANSFER_PATH } from '../../config/paths';

const flagInternalParcelActivity = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_INTERNAL_PARCEL_ACTIVITY,
    payload: flag,
  });

const flagGettingParcels = (flag) => (dispatch) =>
  dispatch({
    type: FLAG_GETTING_INTERNAL_PARCELS,
    payload: flag,
  });

const clearInternalParcels = () => (dispatch) =>
  dispatch({
    type: CLEAR_INTERNAL_PARCELS,
  });

const getInternalParcels = async (tableFilters) => async (dispatch) => {
  try {
    dispatch(flagGettingParcels(true));
    const query = tableFilters;
    const url = `${INTERNAL_PARCEL_ENDPOINT}?${QueryString.stringify(query)}`;
    const response = await fetch(url, DEFAULT_GET_CONFIG);
    await isErrorResponse(response);
    const parcels = await response.json();
    dispatch({
      type: GET_INTERNAL_PARCELS,
      payload: parcels,
    });
  } catch (error) {
    toastr.error('Error', error.message);
  } finally {
    dispatch(flagGettingParcels(false));
  }
};

const postInternalParcel =
  async ({
    voucherTypeId,
    sourceCityId,
    sourceLocationId,
    destinationCityId,
    destinationLocationId,
    senderCustomerId,
    consigneeCustomerId,
    comments,
    internalCargoItemList,
  }) =>
  async (dispatch) => {
    try {
      dispatch(flagInternalParcelActivity(true));
      const payload = {
        voucherTypeId,
        sourceCityId,
        sourceLocationId,
        destinationCityId,
        destinationLocationId,
        senderCustomerId,
        consigneeCustomerId,
        comments,
        internalCargoItemList,
      };
      const url = INTERNAL_PARCEL_ENDPOINT;
      const response = await fetch(url, {
        ...DEFAULT_POST_CONFIG,
        body: JSON.stringify(payload),
      });
      await isErrorResponse(response);
      const parcel = await response.json();
      dispatch(push(`${INTERNAL_TRANSFER_PATH}/${parcel.id}`));
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagInternalParcelActivity(false));
    }
  };

const getInternalParcel =
  async ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(flagInternalParcelActivity(true));
      const url = `${INTERNAL_PARCEL_ENDPOINT}/${id}`;
      const response = await fetch(url, { ...DEFAULT_GET_CONFIG });
      await isErrorResponse(response);
      const parcel = await response.json();
      dispatch({
        type: GET_INTERNAL_PARCEL,
        payload: parcel,
      });
    } catch (error) {
      toastr.error('Error', error.message);
    } finally {
      dispatch(flagInternalParcelActivity(false));
    }
  };

const clearInternalParcel = () => (dispatch) =>
  dispatch({
    type: CLEAR_INTERNAL_PARCEL,
  });

export {
  postInternalParcel,
  getInternalParcels,
  clearInternalParcels,
  flagInternalParcelActivity,
  getInternalParcel,
  clearInternalParcel,
};
