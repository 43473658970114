import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { reduxForm, Field, change } from 'redux-form';
import { Form, Button, FormGroup, Col } from 'reactstrap';
import FormItem from '../../../common/forms/FormItem';
import CitySelect from '../../../common/forms/select/CitySelect';
import { isRequired } from '../../../../utils/validators';
import DatePicker from '../../../common/forms/input/DatePicker';
import {
  getCargoItemToItinerary,
  clearItemToItinerary,
  putAddItemToItinerary,
  getUserAgencies,
  getItineraryForCounter,
  updateItemToItinerary,
  clearItinerary,
} from '../../../../actions';
import { DATE_FORMAT, TIMESTAMP_FORMAT } from '../../../../config/locale';
import { DEFAULT_QUERY_GET_ALL } from '../../../../config/queries';
import Select from '../../../common/forms/select/Select';
import { optionsPropTypes } from '../../../common/forms/select/SelectPropTypes';
import ItinerarySearchInput from '../../../common/forms/input/ItinerarySearchInput';
import Checkbox from '../../../common/forms/Checkbox';
import { tzNormalizeDate } from '../../../../utils/date';
import ItemDetailsModal from './itemToItineraryModalForm';
import Modal from '../../../common/modal/Modal';
import ItemToItineraryResult from './ItemToItineraryResult';
import { getUniqueLocations } from '../unloading-cargo-from-itinerary/UnloadingCargoForm';

export class ItemToItineraryForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itinerary: null,
      modalBody: null,
      modalOpen: false,
      selectedItem: null,
      sourceLocationSelected: null,
      searchFormValues: {
        sourceLocationId: 1,
        destinationCityId: 1,
        itineraryId: 1,
        createDate: tzNormalizeDate({
          format: DATE_FORMAT,
        }),
      },
    };
  }

  componentDidMount() {
    const { user, dispatchGetUserAgencies } = this.props;
    if (user) {
      dispatchGetUserAgencies({ userId: user.id });
    }
  }

  componentWillUnmount() {
    const { dispatchClearItemToItinerary, dispatchClearItinerary } = this.props;
    dispatchClearItemToItinerary();
    dispatchClearItinerary();
  }

  onHandleSubmit = ({ createDate, sourceCity, destinationCity }) => {
    const { dispatchGetCargoItemToItinerary, dispatchClearItemToItinerary } =
      this.props;
    const { itinerary: itineraryId } = this.state;
    const createDateFormatted = tzNormalizeDate({
      date: createDate,
      format: DATE_FORMAT,
    });
    const sourceLocationId = sourceCity.value;
    const destinationCityId = destinationCity.value;

    const itemToItineraryFormValues = {
      sourceLocationId,
      destinationCityId,
      itineraryId,
      createDate: createDateFormatted,
    };

    this.setState({
      sourceLocationSelected: sourceCity.value,
      searchFormValues: itemToItineraryFormValues,
    });

    const query = {
      ...DEFAULT_QUERY_GET_ALL,
      sort: 'id,desc',
      size: 10,
    };

    dispatchClearItemToItinerary();
    dispatchGetCargoItemToItinerary({
      ...itemToItineraryFormValues,
      ...query,
    });
  };

  onClickAddItemToItinerary = async (
    cargoItemId,
    cargoItemCorrelation,
    internalCargoItem,
    selectLocation,
  ) => {
    const date = tzNormalizeDate();
    const {
      dispatchAddItemToItinerary,
      user,
      itineraryCounter,
      dispatchUpdateItemToItinerary,
      items,
    } = this.props;
    const { itinerary: itineraryId, sourceLocationSelected } = this.state;

    const added = await dispatchAddItemToItinerary({
      cargoItemId,
      itineraryId,
      locationId: sourceLocationSelected,
      destinationLocationId: parseInt(selectLocation, 10),
      createDate: tzNormalizeDate({ date, format: TIMESTAMP_FORMAT }),
      exitDate: itineraryCounter.get('itineraryCounter'),
      internalCargoItem,
      userId: user.id,
    });

    if (added) {
      const itemContent = items.get('content');
      const updatedItem = itemContent.find(
        (item) => item.correlation === cargoItemCorrelation,
      );
      const updatedItemWithNewValues = {
        ...updatedItem,
        itineraryId,
        locationId: null,
      };
      dispatchUpdateItemToItinerary(updatedItemWithNewValues);
    }
  };

  onCancel = () => {
    this.setState({ modalOpen: false, modalBody: null, selectedItem: null });
  };

  // obtains the first location id of the user's agency
  getLocationIdByFirstAgencyUser = () => {
    const { SourceLocationOptions } = this.props;

    if (!SourceLocationOptions || SourceLocationOptions.length === 0) {
      return null;
    }

    return SourceLocationOptions[0].locationId;
  };

  convertLocationToOption = () => {
    const locationsArray = [];

    getUniqueLocations(this.props.itineraryCounter).forEach((location) => {
      locationsArray.push({
        value: location.id,
        label: location.name,
      });
    });

    return locationsArray;
  };

  toggleModal = (handleSubmit, item) => {
    const { dispatchChange } = this.props;

    dispatchChange('ItemToItineraryForm', 'selectLocation', '');

    const modalBody = (
      <ItemDetailsModal
        item={item}
        options={this.convertLocationToOption()}
        handleProcess={handleSubmit(this.handleSubmitModal)}
        onCancel={this.onCancel}
      />
    );

    this.setState((prevState) => ({
      modalOpen: !prevState.modalOpen,
      selectedItem: item,
      modalBody,
    }));
  };

  executeAddItem = (item, handleSubmit) => {
    const foundId = getUniqueLocations(this.props.itineraryCounter).find(
      (obj) => obj.id === item.destinationLocationId,
    );

    if (foundId) {
      this.onClickAddItemToItinerary(
        item.id,
        item.correlation,
        item.internalCargoItem,
        item.destinationLocationId,
      );
    } else {
      this.toggleModal(handleSubmit, item);
    }
  };

  generateCargoItemColumns = () => {
    const { handleSubmit } = this.props;

    return [
      {
        Header: 'Cantidad',
        accessor: 'quantity',
        className: 'text-center',
      },
      {
        Header: 'Descripción',
        accessor: 'description',
        className: 'text-center',
      },
      {
        Header: 'Peso (kg)',
        accessor: 'weight',
        className: 'text-center',
      },
      {
        Header: 'Correlativo',
        accessor: 'correlation',
        className: 'text-center',
      },
      {
        Header: 'Acción',
        className: 'text-center',
        Cell: (row) =>
          row.original.locationId === null ? (
            <Checkbox checked />
          ) : (
            <i
              tabIndex="0"
              role="button"
              className="fa fa-plus-circle text-success"
              onClick={() => this.executeAddItem(row.original, handleSubmit)}
              onKeyPress={() => this.executeAddItem(row.original, handleSubmit)}
            />
          ),
        width: 150,
        sortable: false,
      },
    ];
  };

  handleSelectItinerary = ({ itineraryId }) => {
    this.setState({ itinerary: itineraryId });
    this.props.dispatchGetItineraryForCounter(itineraryId);
  };

  handleShowItinerarySearch = () => {
    const { dispatchClearItemToItinerary, dispatchChange } = this.props;
    dispatchClearItemToItinerary();
    dispatchChange('ItemToItineraryForm', 'itineraryId', '');
    dispatchChange('ItemToItineraryForm', 'itinerary', '');
  };

  handleSubmitModal = (selectLocation) => {
    const { selectedItem } = this.state;

    this.onClickAddItemToItinerary(
      selectedItem.id,
      selectedItem.correlation,
      selectedItem.internalCargoItem,
      selectLocation.selectLocation.value,
    );
    this.setState({
      modalOpen: false,
      selectedItem: null,
    });
  };

  render() {
    const { handleSubmit, loadingAgencies, agencyOptions } = this.props;

    const { modalOpen, selectedItem, searchFormValues } = this.state;
    return (
      <>
        <Form onSubmit={handleSubmit(this.onHandleSubmit)}>
          <FormGroup row>
            <FormItem label="Itinerario" required>
              <Field
                name="itineraryId"
                component={ItinerarySearchInput}
                placeholder="Itinerario"
                handleSelectItinerary={this.handleSelectItinerary}
                handleShowItinerarySearch={this.handleShowItinerarySearch}
                validate={[isRequired]}
                props={{
                  form: 'ItemToItineraryForm',
                }}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Fecha de Recepción" required>
              <Field
                name="createDate"
                component={DatePicker}
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Origen" required>
              <Field
                name="sourceCity"
                component={Select}
                isLoading={loadingAgencies}
                options={agencyOptions}
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <FormItem label="Destino" required>
              <Field
                name="destinationCity"
                component={CitySelect}
                validate={[isRequired]}
              />
            </FormItem>
          </FormGroup>
          <FormGroup row>
            <Col className="flex row-reverse">
              <Button type="submit" color="primary">
                Buscar
              </Button>
            </Col>
          </FormGroup>
        </Form>
        <h5>Items de Cargo</h5>
        <ItemToItineraryResult
          columns={this.generateCargoItemColumns()}
          searchFormValues={searchFormValues}
        />
        {selectedItem && (
          <Modal
            show={modalOpen}
            title="Cambiar destino de Item"
            onClickClose={this.closeModal}
            body={this.state.modalBody}
            size="lg"
          />
        )}
      </>
    );
  }
}

ItemToItineraryForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  dispatchGetCargoItemToItinerary: PropTypes.func.isRequired,
  dispatchGetItineraryForCounter: PropTypes.func.isRequired,
  dispatchClearItemToItinerary: PropTypes.func.isRequired,
  dispatchAddItemToItinerary: PropTypes.func.isRequired,
  dispatchChange: PropTypes.func.isRequired,
  items: PropTypes.instanceOf(Immutable.Map).isRequired,
  dispatchGetUserAgencies: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    customer: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  loadingAgencies: PropTypes.bool,
  agencyOptions: optionsPropTypes,
  itineraryCounter: PropTypes.instanceOf(Immutable.Map).isRequired,
  SourceLocationOptions: optionsPropTypes,
  dispatchUpdateItemToItinerary: PropTypes.func.isRequired,
  dispatchClearItinerary: PropTypes.func.isRequired,
};

ItemToItineraryForm.defaultProps = {
  loadingAgencies: false,
  agencyOptions: [],
  SourceLocationOptions: [],
};

const mapStateToProps = ({
  CargoUnit,
  authentication,
  HumanResourcesUnit,
  ItineraryUnit,
}) => ({
  items: CargoUnit.ItemToItinerary.getIn(['current', 'items']),
  user: authentication.get('user') || undefined,
  loadingAgencies: HumanResourcesUnit.Agency.getIn(['all', 'loading']),
  agencyOptions: HumanResourcesUnit.Agency.getIn([
    'all',
    'content',
    'content',
  ]).map((agency) => ({
    value: agency.locationId,
    label: agency.name,
  })),
  itineraryCounter:
    ItineraryUnit.Itinerary.getIn(['current', 'content']) || undefined,
  SourceLocationOptions: HumanResourcesUnit.Agency.getIn([
    'all',
    'content',
    'content',
  ]).map((agency) => ({
    id: agency.id,
    name: agency.name,
    locationId: agency.locationId,
  })),
});

const mapDispatchToProps = {
  dispatchGetCargoItemToItinerary: getCargoItemToItinerary,
  dispatchGetItineraryForCounter: getItineraryForCounter,
  dispatchClearItemToItinerary: clearItemToItinerary,
  dispatchChange: change,
  dispatchAddItemToItinerary: putAddItemToItinerary,
  dispatchGetUserAgencies: getUserAgencies,
  dispatchUpdateItemToItinerary: updateItemToItinerary,
  dispatchClearItinerary: clearItinerary,
};

const formComponent = reduxForm({
  form: 'ItemToItineraryForm',
})(ItemToItineraryForm);

export default connect(mapStateToProps, mapDispatchToProps)(formComponent);
