import React from 'react';
import { connect } from 'react-redux';

import { breadcrumbsPropTypes } from '../../../common/resource/proptypes/CommonPropTypes';
import Content from '../../../layout/Content';
import ItineraryManifestForm from './ItineraryManifestForm';

export const ManifestItinerary = ({ breadcrumbs }) => (
  <Content
    breadcrumbs={breadcrumbs}
    title="Manifiesto del Itinerario"
    subtitle="Manifiesto del Itinerario"
    content={<ItineraryManifestForm />}
  />
);

ManifestItinerary.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Manifiesto de Itinerarios',
      href: '',
    },
  ],
});

export default connect(mapStateToProps)(ManifestItinerary);
