import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from './Select';
import { DEFAULT_SELECT_PLACEHOLDER } from '../../../../config/constants';
import { clearCities, getCities } from '../../../../actions';
import { optionsPropTypes } from './SelectPropTypes';

class CitySelect extends Component {
  componentWillUnmount() {
    const { dispatchClearCities } = this.props;
    dispatchClearCities();
  }

  handleInputChange = (inputValue) => {
    const { dispatchGetCities } = this.props;
    if (inputValue.trim().length && inputValue.trim().length >= 4) {
      dispatchGetCities({ query: inputValue });
    }
  };

  render() {
    const {
      loading,
      options,
      isGridVariant,
      value,
      isMulti,
      isClearable,
      onValueChange,
      placeholder,
      ...rest
    } = this.props;
    return (
      <Select
        isLoading={loading}
        options={options}
        isMulti={isMulti}
        input={{ value }}
        onValueChange={onValueChange}
        isClearable={isClearable}
        isGridVariant={isGridVariant}
        onInputChange={this.handleInputChange}
        placeholder={placeholder}
        {...rest}
      />
    );
  }
}

CitySelect.propTypes = {
  isGridVariant: PropTypes.bool,
  options: optionsPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
  noOptionsMessage: PropTypes.func,
  dispatchGetCities: PropTypes.func.isRequired,
  dispatchClearCities: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  onValueChange: PropTypes.func,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
};

CitySelect.defaultProps = {
  noOptionsMessage: undefined,
  isDisabled: false,
  isGridVariant: false,
  onValueChange: undefined,
  isClearable: false,
  value: {},
  isMulti: false,
  placeholder: DEFAULT_SELECT_PLACEHOLDER,
};

const mapStateToProps = ({ LocationUnit }) => ({
  options: LocationUnit.City.getIn(['all', 'content', 'content']).map(
    (city) => ({
      value: city.id,
      label: `${city.name}, ${city.region.name}, ${city.region.country.name}`,
    }),
  ),
  loading: LocationUnit.City.getIn(['all', 'loading']),
});

const mapDispatchToProps = {
  dispatchGetCities: getCities,
  dispatchClearCities: clearCities,
};

export default connect(mapStateToProps, mapDispatchToProps)(CitySelect);
