import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import {
  breadcrumbsPropTypes,
  matchPropTypes,
} from '../../../common/resource/proptypes/CommonPropTypes';
import { CARGO_PATH } from '../../../../config/paths';
import { getParcel, clearParcel } from '../../../../actions';
import Loader from '../../../common/Loader';
import Content from '../../../layout/Content';
import ParcelToolbar from './ParcelToolbar';
import NoDataResource from '../../../common/resource/NoDataResource';
import ParcelBasicInformationResource from './resource/ParcelBasicInformationResource';

const Parcel = ({
  breadcrumbs,
  parcel,
  loading,
  dispatchGetParcel,
  match: {
    params: { id },
  },
  dispatchClearParcel,
}) => {
  useEffect(() => {
    dispatchGetParcel({ parcelId: id });

    return () => {
      dispatchClearParcel();
    };
  }, []);

  let content = null;
  let toolbar = null;
  if (loading) content = <Loader />;
  else if (parcel.isEmpty())
    content = <NoDataResource returnPage={CARGO_PATH} />;
  else {
    const parcelJson = parcel.toJS();
    content = <ParcelBasicInformationResource {...parcelJson} />;
    toolbar = <ParcelToolbar parcel={parcelJson} />;
  }
  return (
    <Content
      toolbar={toolbar}
      breadcrumbs={breadcrumbs}
      title="Carga"
      content={content}
    />
  );
};
Parcel.propTypes = {
  breadcrumbs: breadcrumbsPropTypes.isRequired,
  dispatchGetParcel: PropTypes.func.isRequired,
  dispatchClearParcel: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  match: matchPropTypes.isRequired,
  parcel: PropTypes.instanceOf(Immutable.Map).isRequired,
};

Parcel.defaultProps = {
  loading: false,
};

const mapStateToProps = ({ CargoUnit }) => ({
  breadcrumbs: [
    ...CargoUnit.UnitHome.get('breadcrumbs'),
    {
      text: 'Carga',
      href: CARGO_PATH,
    },
    {
      text: 'Ver',
      href: '',
    },
  ],
  parcel: CargoUnit.Cargo.getIn(['current', 'content']),
  loading: !CargoUnit.Cargo.getIn(['current', 'activity']).isEmpty(),
});

const mapDispatchToProps = {
  dispatchGetParcel: getParcel,
  dispatchClearParcel: clearParcel,
};

export default connect(mapStateToProps, mapDispatchToProps)(Parcel);
