import Immutable from 'immutable';
import {
  FLAG_GETTING_ITINERARY_SALES_CONTROL,
  GET_ITINERARY_SALES_CONTROL,
  CLEAR_ITINERARY_SALES_CONTROL,
  GET_ITINERARY_SALES_CONTROL_DETAIL,
  CLEAR_ITINERARY_SALES_CONTROL_DETAIL,
  FLAG_GETTING_ITINERARY_SALES_CONTROL_DETAIL,
  GET_LIVE_ITINERARY_SALES_CONTROL,
  CLEAR_LIVE_ITINERARY_SALES_CONTROL,
  FLAG_GETTING_LIVE_ITINERARY_SALES_CONTROL,
} from '../../../actions/types';
import { COLLECTION_SHAPE } from '../../../config/shapes';

const INITIAL_STATE = Immutable.Map({
  all: Immutable.Map({
    content: Immutable.Map(COLLECTION_SHAPE),
    loading: false,
  }),
  detail: Immutable.Map({
    content: Immutable.Set([]),
    loading: false,
  }),
  live: Immutable.Map({
    content: Immutable.Map({
      page: COLLECTION_SHAPE,
      itineraryOccupancyDto: {},
    }),
    loading: false,
  }),
});

export default (state = INITIAL_STATE, { payload, type }) => {
  switch (type) {
    case FLAG_GETTING_ITINERARY_SALES_CONTROL:
      return state.setIn(['all', 'loading'], payload);
    case GET_ITINERARY_SALES_CONTROL:
      return state.setIn(['all', 'content'], Immutable.Map(payload));
    case CLEAR_ITINERARY_SALES_CONTROL:
      return state.setIn(
        ['all', 'content'],
        INITIAL_STATE.getIn(['all', 'content']),
      );
    case GET_ITINERARY_SALES_CONTROL_DETAIL:
      return state.setIn(['detail', 'content'], Immutable.Set(payload));
    case CLEAR_ITINERARY_SALES_CONTROL_DETAIL:
      return state.setIn(['detail'], INITIAL_STATE.getIn(['detail']));
    case FLAG_GETTING_ITINERARY_SALES_CONTROL_DETAIL:
      return state.setIn(['detail', 'loading'], payload);
    case FLAG_GETTING_LIVE_ITINERARY_SALES_CONTROL:
      return state.setIn(['live', 'loading'], payload);
    case GET_LIVE_ITINERARY_SALES_CONTROL:
      return state.setIn(['live', 'content'], Immutable.Map(payload));
    case CLEAR_LIVE_ITINERARY_SALES_CONTROL:
      return state.setIn(
        ['live', 'content'],
        INITIAL_STATE.getIn(['live', 'content']),
      );
    default:
      return state;
  }
};
