import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from './Select';
import { getRegisteredBuses, clearRegisteredBuses } from '../../../../actions';
import {
  inputPropTypes,
  metaPropTypes,
  inputDefaultProps,
  metaDefaultProps,
} from '../../forms/input/InputPropTypes';
import { registeredBusesGenerator } from '../../../../utils/app/json-generator-from-reducer';
import { registeredBusBasicInformationPropTypes } from '../../../units/bus/registered-bus/proptypes/RegisteredBusPropTypes';

class RegisteredBusSelect extends Component {
  componentWillUnmount() {
    const { dispatchClearRegisteredBuses } = this.props;

    dispatchClearRegisteredBuses();
  }

  handleRegisteredBusChange = (inputValue) => {
    const { dispatchGetRegisteredBuses } = this.props;

    if (inputValue.trim().length) {
      dispatchGetRegisteredBuses({ query: `companyBusId:${inputValue}` });
    }
  };

  render() {
    const {
      registeredBuses,
      isLoading,
      isMulti,
      input,
      meta,
      isDisabled,
      isGridVariant,
      onBlur,
      onValueChange,
      autoFocus,
      ...rest
    } = this.props;

    return (
      <Select
        options={registeredBuses}
        isLoading={isLoading}
        isMulti={isMulti}
        input={input}
        meta={meta}
        isDisabled={isDisabled}
        isGridVariant={isGridVariant}
        onValueChange={onValueChange}
        onInputChange={this.handleRegisteredBusChange}
        onBlur={onBlur}
        autoFocus={autoFocus}
        {...rest}
      />
    );
  }
}

RegisteredBusSelect.propTypes = {
  dispatchGetRegisteredBuses: PropTypes.func.isRequired,
  dispatchClearRegisteredBuses: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  input: inputPropTypes,
  registeredBuses: PropTypes.arrayOf(
    PropTypes.shape(registeredBusBasicInformationPropTypes),
  ),
  meta: metaPropTypes,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isGridVariant: PropTypes.bool,
  onValueChange: PropTypes.func,
  onBlur: PropTypes.func,
  autoFocus: PropTypes.bool,
};

RegisteredBusSelect.defaultProps = {
  input: inputDefaultProps,
  meta: metaDefaultProps,
  isLoading: false,
  isDisabled: false,
  isMulti: false,
  registeredBuses: [],
  isGridVariant: false,
  onValueChange: undefined,
  onBlur: undefined,
  autoFocus: false,
};

const mapStateToProps = ({ BusUnit }) => ({
  registeredBuses: registeredBusesGenerator(
    BusUnit.RegisteredBus.getIn(['all', 'content', 'content']),
  ),
  isLoading: BusUnit.RegisteredBus.getIn(['all', 'loading']),
});

const mapDispatchToprops = {
  dispatchGetRegisteredBuses: getRegisteredBuses,
  dispatchClearRegisteredBuses: clearRegisteredBuses,
};

export default connect(
  mapStateToProps,
  mapDispatchToprops,
)(RegisteredBusSelect);
